import React from "react";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Select from "react-select";
import { getStorageValue } from "../../Utils";

const Filter = ({
  context,
  userOptionsOwners,
  userOptionsVPs,
  userOptionsCSC,
  userOptionsSocialAdSpecialist,
  filter,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [owner, setOwner] = useState(
    getStorageValue("gargle_analytics_owner_id")
  );
  const [vp, setVP] = useState(getStorageValue("gargle_analytics_vp_id"));
  const [csc, setCSC] = useState(getStorageValue("gargle_analytics_csc_id"));
  const [socialAdSpecialist, setSocialAdSpecialist] = useState(
    getStorageValue("gargle_social_ad_specialist_id")
  );

  return (
    <div
      className="filter-container"
      style={{
        marginTop: showFilter ? "0" : "-80px",
        position: "absolute",
        zIndex: 50000,
        width: "99%",
        left: 0,
        top: 0,
      }}
    >
      <Row
        style={{
          minHeight: "auto",
          background: "white",
          borderBottom: "1px solid #CBD0D5",
        }}
      >
        <Col
          style={{
            paddingTop: "1px",
            paddingBottom: "1px",
            marginBottom: "2px",
          }}
          className="filter-container"
        >
          {userOptionsOwners && (
            <Select
              value={userOptionsOwners.find((item) => item.value === owner)}
              options={userOptionsOwners}
              placeholder="Owner / CS Director"
              onChange={(val) => {
                context.OwnerId = val.value;
                window.localStorage.setItem(
                  "gargle_analytics_owner_id",
                  val.value
                );
                setOwner(context.OwnerId);
              }}
            />
          )}
          {userOptionsCSC && (
            <Select
              value={userOptionsCSC.find((item) => item.value === csc)}
              options={userOptionsCSC}
              placeholder="CS Coordinator"
              onChange={(val) => {
                context.CSCId = val.value;
                window.localStorage.setItem(
                  "gargle_analytics_csc_id",
                  val.value
                );
                setCSC(context.CSCId);
              }}
            />
          )}
          {userOptionsVPs && (
            <Select
              value={userOptionsVPs.find((item) => item.value === vp)}
              options={userOptionsVPs}
              placeholder="VP"
              onChange={(val) => {
                context.VPId = val.value;
                window.localStorage.setItem(
                  "gargle_analytics_vp_id",
                  val.value
                );
                setVP(context.VPId);
              }}
            />
          )}
          {userOptionsSocialAdSpecialist && (
            <Select
              value={userOptionsSocialAdSpecialist.find(
                (item) => item.value === socialAdSpecialist
              )}
              options={userOptionsSocialAdSpecialist}
              placeholder="Social Ad Specialist"
              onChange={(val) => {
                context.SocialAdSpecialistId = val.value;
                window.localStorage.setItem(
                  "gargle_social_ad_specialist_id",
                  val.value
                );
                setSocialAdSpecialist(context.SocialAdSpecialistId);
              }}
            />
          )}
          <Button
            variant="primary"
            onClick={(e) => {
              filter(e, setShowFilter);
            }}
          >
            Filter
          </Button>
        </Col>
      </Row>
      <div
        style={{
          width: "100%",
          textAlign: "right",
          marginTop: "-1px",
        }}
      >
        <div
          style={{
            borderRight: "1px solid #CBD0D5",
            borderBottom: "1px solid #CBD0D5",
            borderLeft: "1px solid #CBD0D5",
            fontSize: "12px",
            padding: "8px 15px 4px 15px",
            background: "white",
            cursor: "pointer",
            width: "140px",
            display: "inline-block",
            textAlign: "center",
            marginRight: "5px",
            borderRadius: "0 0 5px 5px",
            color: "gray",
          }}
          onClick={() => window.location.reload()}
        >
          <FontAwesomeIcon
            icon={faRedo}
            style={{ marginRight: "10px", fontSize: "10px" }}
          />
          Refresh Page
        </div>
        <div
          style={{
            borderRight: "1px solid #CBD0D5",
            borderBottom: "1px solid #CBD0D5",
            borderLeft: "1px solid #CBD0D5",
            fontSize: "12px",
            padding: "8px 15px 4px 15px",
            background: "white",
            cursor: "pointer",
            width: "120px",
            display: "inline-block",
            textAlign: "center",
            marginRight: "5px",
            borderRadius: "0 0 5px 5px",
            color: "gray",
          }}
          onClick={() => setShowFilter((prev) => !prev)}
        >
          {showFilter ? "Hide Filter" : "Show Filter"}
        </div>
      </div>
    </div>
  );
};

export default Filter;
