import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../App";
import { getStats } from "../../../SF";
import "../../../../stats.css";
import StatComponent from "../StatComponent";

function Stats() {
  const [data, setData] = useState(null);

  const context = useContext(UserContext);

  useEffect(() => {
    console.log(data);
    if (!data) {
      getStats(context, "This Month").then(function (result) {
        setData(result);
      });
    }
  });

  return <StatComponent stats={data} title={"This Month"} />;
}

export default Stats;
