import React, { useCallback, useContext, useEffect, useState } from "react";
import { sfQuery } from "../API";
import { UserContext } from "../../App";
import Loader from "../UI/Loader";

const SelectAccount = ({ callbackFunction }) => {
  const context = useContext(UserContext);

  const [accountList, setAccountList] = useState(null);
  const [accountNexhealtId, setAccountNexhealthId] = useState(
    context.CURRENT_ACCOUNT_NEXHEALTH_ID
  );
  const [accountId, setAccountId] = useState(context.CURRENT_ACCOUNT_ID);

  const refresh = useCallback(
    async (load) => {
      if (context.accessToken !== null && load && !context.isLoading) {
        try {
          if (!accountList) {
            const accLst = await sfQuery(
              "Account",
              "Id, Name, NexHealth_ID__c, NexHealth_Subdomain__c",
              "NexHealth_Id__c != null",
              context
            );
            setAccountList(accLst);
          }
        } catch (error) {
          console.error("Error during refresh:", error);
        }
      }
    },
    [context, accountList] // Add dependencies
  );

  useEffect(() => {
    refresh(!accountList);
  }, [accountList, refresh]);

  return (
    <>
      {!accountList && <Loader />}
      {accountList && (
        <div className="select-container">
          <select
            onChange={(e) => {
              const selectedOption = e.target.options[e.target.selectedIndex];
              callbackFunction(e.target.value);
              setAccountNexhealthId(e.target.value);
              setAccountId(selectedOption.dataset.key);
              context.CURRENT_ACCOUNT_NEXHEALTH_ID = e.target.value;
              context.CURRENT_ACCOUNT_ID = selectedOption.dataset.key;
            }}
            value={accountNexhealtId}
            data-account-id={accountId}
          >
            <option value="">Select Account</option>
            {accountList.map((account) => (
              <option
                key={account.Id}
                value={account.NexHealth_ID__c}
                data-key={account.Id}
              >
                {account.Name}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
};

export default SelectAccount;
