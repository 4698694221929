import {
  blueColor,
  greenColor,
  multicolor,
  orangeColor,
  redColor,
  StackedGreenRedSetup,
  StackedGreenYellowRedGraySetup,
  StackedGreenYellowRedSetup,
  StackedMulticolorSetup,
  yellowColor,
} from "../../UI/DMCharts";

import _ from "lodash";
import { getDate } from "../../Utils";

export const _bookHygieneNoOrders = () => {
  return {
    labels: ["Order Review"],
    datasets: StackedGreenYellowRedSetup([
      "No Orders",
      "Less than expected",
      "As expected",
    ]),
  };
};

export const _bookHygieneNoPayment = () => {
  return {
    labels: ["Payment Status"],
    datasets: StackedGreenYellowRedSetup([
      "Overdue",
      "No Payment Date",
      "Paid On Time",
    ]),
  };
};

export const _bookHygieneInPlan = () => {
  return {
    labels: ["On Plan Status"],
    datasets: StackedGreenYellowRedSetup([
      "2 Months Due",
      "1 Month Due",
      "On Plan",
    ]),
  };
};
export const _bookHygieneNoActivities = () => {
  return {
    labels: ["Active Clients"],
    datasets: StackedGreenRedSetup(["No Open Activity", "Activities Planned"]),
  };
};
export const _proofreading = () => {
  return {
    labels: [
      "Print Week is Current Week",
      "Print Week is 1 Week Away",
      "Print Week is 2 or more weeks away",
    ],
    datasets: [
      {
        label: "Proofreading",
        data: [],
        backgroundColor: [redColor, yellowColor, greenColor],
      },
    ],
  };
};
export const _firstCalls = () => {
  return {
    labels: ["First Call Tasks"],
    datasets: StackedGreenYellowRedGraySetup([
      "Missing Task",
      "Open",
      "Completed",
      "-",
    ]),
  };
};
export const _fulfillment = () => {
  return {
    labels: ["Fulfillment Status"],
    datasets: StackedGreenYellowRedGraySetup([
      "Shipped / Sent to Printer",
      "En Route To Homes",
      "Currently Receiving Calls",
    ]),
  };
};
export const _noStart = () => {
  return {
    labels: ["No Starts"],
    datasets: StackedGreenYellowRedGraySetup([
      "Neighborhood Route",
      "New Move-In",
      "-",
    ]),
  };
};

export const _shippingNotes = () => {
  return {
    labels: ["Shipping Notes"],
    datasets: StackedGreenYellowRedGraySetup([
      "Delayed Shipping",
      "Shipping Notes",
      "-",
    ]),
  };
};

export const _progressReport = () => {
  return {
    labels: ["Progress Report Sent"],
    datasets: StackedGreenYellowRedGraySetup([
      "Not Yet Sent",
      "Not Yet Sent",
      "Sent",
    ]),
  };
};

export const _saiDue = () => {
  return {
    labels: ["Last 30 Days"],
    datasets: StackedGreenYellowRedGraySetup([
      "No Date | Completed Late",
      "2 Day Overdue",
      "Completed Within 2 Days",
    ]),
  };
};

export const _rrTasks = () => {
  return {
    labels: ["Last 30 Days"],
    datasets: StackedGreenYellowRedGraySetup([
      "Overdue",
      "Open Due Today",
      "Completed / Within time",
      "No RR",
    ]),
  };
};

export const _designStages = () => {
  return {
    labels: [],
    datasets: StackedGreenYellowRedSetup([
      "No Stage",
      "Not Aproved Yet",
      "Approved",
    ]),
  };
};

export const _mapStages = () => {
  return {
    labels: [],
    datasets: StackedGreenYellowRedSetup([
      "No Stage",
      "Not Aproved Yet",
      "Approved",
    ]),
  };
};

export const _BOB = () => {
  return {
    labels: ["Status"],
    datasets: StackedMulticolorSetup([
      "DM - NEW",
      "Active - Initial",
      "Active - Renewed",
      "Active - Additional",
      "Active - Corporate",
      "Active - Determine Relationship",
    ]),
  };
};

export const _BOBbyStatus = () => {
  return {
    labels: [
      "DM - NEW",
      "Active - Initial",
      "Active - Renewed",
      "Active - Additional",
      "Active - Corporate",
      "Active - Determine Relationship",
    ],
    datasets: [
      {
        label: "0",
        data: [],
        backgroundColor: [
          multicolor[0],
          multicolor[1],
          multicolor[2],
          multicolor[3],
          multicolor[1],
          orangeColor,
        ],
        hoverOffset: 4,
      },
    ],
  };
};

export const _BOBbyPlan = () => {
  return {
    labels: ["On Plan", "Deviating", "One-Off", "No Plan"],
    datasets: [
      {
        label: "0",
        data: [],
        backgroundColor: [multicolor[0], orangeColor, yellowColor, redColor],
        hoverOffset: 4,
      },
    ],
  };
};

export const _inactiveAdditional = () => {
  return {
    labels: ["Inactive Additional"],
    datasets: StackedGreenYellowRedGraySetup([
      "No Task",
      "Task Within 60d",
      "Task Within 30d",
    ]),
  };
};

export const _lastMinute = () => {
  return {
    labels: ["Last Minute"],
    datasets: [
      {
        label: "0",
        data: [],
        backgroundColor: [redColor],
        hoverOffset: 4,
      },
    ],
  };
};

export const _upcomingRR = () => {
  return {
    labels: ["Upcoming RR"],
    datasets: StackedGreenYellowRedGraySetup([
      "1 Day Left",
      "2-5 Days Left",
      "Completed",
    ]),
  };
};

export const _tasksColumns = () => {
  return [
    {
      name: "Subject",
      selector: (row) => row.Subject,
      sortable: true,
      wrap: true,
      sortFunction: (rowA, rowB) => {
        const a = rowA.Subject.toLowerCase();
        const b = rowB.Subject.toLowerCase();

        return a > b ? 1 : b > a ? -1 : 0;
      },
      style: {
        textAlign: "left",
      },
    },
    {
      name: "Date",
      selector: (row) => row.ActivityDate,
      sortable: true,
      width: "100px",
      style: {
        backgroundColor: "#f7f7f7",
      },
    },
    {
      name: "Account",
      selector: (row) => row.What.Name,
      sortFunction: (rowA, rowB) => {
        const a = rowA.What.Name.toLowerCase();
        const b = rowB.What.Name.toLowerCase();

        return a > b ? 1 : b > a ? -1 : 0;
      },
      sortable: true,
    },
  ];
};

export const _groupTasks = (tasks, tasksArray, today) => {
  _.forIn(
    _.groupBy(
      tasks.filter((item) => {
        return today
          ? getDate(item.ActivityDate).toDateString() ===
              getDate().toDateString()
          : getDate(item.ActivityDate) < getDate();
      }),
      "Owner.Name"
    ),
    (data) => {
      tasksArray.push(data);
    }
  );
};

export const _completedDirectorAccReview = () => {
  return {
    labels: ["Completed Director Account Review"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _summaryAndActionItemEmails = () => {
  return {
    labels: ["Summary and Action Item Emails"],
    datasets: StackedGreenYellowRedGraySetup([
      "Sent after 2 biz days",
      "Sent within 2 biz days",
      "Sent within 1 biz day",
    ]),
  };
};

export const _lastVPContact = () => {
  return {
    labels: ["Last VP Contact"],
    datasets: StackedGreenYellowRedGraySetup([
      "Last contact was after 6 months",
      "-",
      "Last contact was within 6 months",
      "No contact yet",
    ]),
  };
};

export const _noOpenActivities = () => {
  return {
    labels: ["No Open Activities"],
    datasets: StackedGreenYellowRedGraySetup(["Not Open", "Not Open", "-"]),
  };
};

export const _noShow = () => {
  return {
    labels: ["No Shows"],
    datasets: StackedGreenYellowRedGraySetup(["No Show", "Contacted", "-"]),
  };
};

export const _overdueActivities = () => {
  return {
    labels: ["Overdue Activities"],
    datasets: StackedGreenYellowRedGraySetup([
      "Not Cmpleted",
      "Not Completed",
      "-",
    ]),
  };
};

export const _riskOfCancellation = () => {
  return {
    labels: [
      "High Risk & Pending Cancellation this Month",
      "Concerned",
      "Going Great",
      "Not Set",
    ],
    datasets: [
      {
        label: "Risk of Cancellation",
        data: [],
        backgroundColor: [redColor, yellowColor, greenColor, orangeColor],
      },
    ],
  };
};

export const _expiringThisMonth = () => {
  return {
    labels: ["Bundle Discounts", "Expiring Discounts", ""],
    datasets: [
      {
        label: "Expiring This Month",
        data: [],
        backgroundColor: [redColor, yellowColor, greenColor],
      },
    ],
  };
};

export const _gmCancellationsThisMonth = () => {
  return {
    labels: ["GM Cancellations This Month"],
    datasets: StackedGreenYellowRedGraySetup(["0-2", "3+", "-"]),
  };
};

export const _productCancellationsThisMonth = () => {
  return {
    labels: ["Product Cancellations This Month"],
    datasets: StackedGreenYellowRedGraySetup(["0-6", "7+", "-"]),
  };
};

export const _overduePayments = () => {
  return {
    labels: ["Overdue Payments"],
    datasets: StackedGreenYellowRedGraySetup(["0", "1+", "-"]),
  };
};

export const _upsellsThisMonth = () => {
  return {
    labels: ["Upsells This Month"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _referralsThisMonth = () => {
  return {
    labels: ["Referrals This Month"],
    datasets: StackedGreenYellowRedGraySetup(["2+", "less than 2", "-"]),
  };
};

export const _discussedReferralOpp = () => {
  return {
    labels: ["Discussed Referral Opportunities"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _gargleReview = () => {
  return {
    labels: ["Gargle Review"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _reviewsThisMonth = () => {
  return {
    labels: ["Reviews This Month"],
    datasets: StackedGreenYellowRedGraySetup([
      "Not Completed",
      "Completed",
      "-",
    ]),
  };
};

export const _directorUpsellDisc = () => {
  return {
    labels: ["Director Up-Sell Discussion"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _openTasks = () => {
  return {
    labels: ["Open Tasks"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _upsellsThisMonthVP = () => {
  return {
    labels: ["Upsells This Month"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _stagingSiteUrgency = () => {
  return {
    labels: ["Staging Site Urgency"],
    datasets: StackedMulticolorSetup([
      "Onboarding",
      "In Progress",
      "Fire",
      "URGENT",
      "Final Stages",
      "-",
    ]),
  };
};

export const _additionalOffice = () => {
  return {
    labels: ["Additional Office"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _contractTerms = () => {
  return {
    labels: ["Month to Month", "-", "Annual"],
    datasets: [
      {
        label: "Contract Terms",
        data: [],
        backgroundColor: [redColor, yellowColor, greenColor],
      },
    ],
  };
};

export const _referralsThisMonthVP = () => {
  return {
    labels: ["Referrals This Month"],
    datasets: StackedGreenYellowRedGraySetup(["2+", "less than 2", "-"]),
  };
};

export const _mrrCancelationDollarsThisMonth = () => {
  return {
    labels: ["MRR Cancelation Dollars This Month"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _gmCancellationsThisMonthVP = () => {
  return {
    labels: ["GM Cancellations This Month"],
    datasets: StackedGreenYellowRedGraySetup(["0-2", "3+", "-"]),
  };
};

export const _productCancellationsThisMonthVP = () => {
  return {
    labels: ["GM Cancellations This Month"],
    datasets: StackedGreenYellowRedGraySetup(["0-2", "3+", "-"]),
  };
};

export const _completedSemiAnnualReviewCall = () => {
  return {
    labels: ["Completed Semi-Annual Review Call"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed Over 6mo",
      "-",
      "Completed Within 6mo",
      "No Task Found",
    ]),
  };
};

export const _noShowSemiAnnualReview = () => {
  return {
    labels: ["No Show"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _accWithCredits = () => {
  return {
    labels: ["Accounts with Credits"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _productsWorking = () => {
  return {
    labels: ["Products Working"],
    datasets: StackedGreenYellowRedGraySetup(["95% +", "less than 95%", "-"]),
  };
};

export const _productPerformanceAlerts = () => {
  return {
    labels: ["Product Performance Alerts"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _summaryAndActionItemEmailsVP = () => {
  return {
    labels: ["Summary And Action Item Emails"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _noOpenActivitiesVP = () => {
  return {
    labels: ["No Open Activities"],
    datasets: StackedGreenYellowRedGraySetup(["Not Open", "Not Open", "-"]),
  };
};

export const _overdueActivitiesVP = () => {
  return {
    labels: ["Overdue Activities"],
    datasets: StackedGreenYellowRedGraySetup([
      "Not Cmpleted",
      "Not Completed",
      "-",
    ]),
  };
};

export const _riskOfCancellationVP = () => {
  return {
    labels: [
      "High Risk & Pending Cancellation this Month",
      "Concerned",
      "Going Great",
    ],
    datasets: [
      {
        label: "Risk of Cancellation",
        data: [],
        backgroundColor: [redColor, yellowColor, greenColor],
      },
    ],
  };
};

export const _productRiskOfCancellation = () => {
  return {
    labels: [
      "High Risk & Pending Cancellation this Month",
      "Concerned",
      "Going Great",
    ],
    datasets: [
      {
        label: "Product Risk of Cancellation",
        data: [],
        backgroundColor: [redColor, yellowColor, greenColor],
      },
    ],
  };
};

export const _productRiskOfCancellationVP = () => {
  return {
    labels: [
      "High Risk & Pending Cancellation this Month",
      "Concerned",
      "Going Great",
    ],
    datasets: [
      {
        label: "Product Risk of Cancellation",
        data: [],
        backgroundColor: [redColor, yellowColor, greenColor],
      },
    ],
  };
};

export const _expiringDiscounts = () => {
  return {
    labels: ["Expiring Discounts"],
    datasets: StackedGreenYellowRedGraySetup([
      "Not Cmpleted",
      "Not Completed",
      "-",
    ]),
  };
};

export const _callPrepsCS = () => {
  return {
    labels: ["Call Preps"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _auditsCS = () => {
  return {
    labels: ["Audit"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _completedDirectorAccReviewCS = () => {
  return {
    labels: ["Completed Director Account Review"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed",
      "Not Completed",
      "-",
    ]),
  };
};

export const _completedVPSemiAnnualReviews = () => {
  return {
    labels: ["Completed Director Account Review"],
    datasets: StackedGreenYellowRedGraySetup([
      "Completed Over 6mo",
      "-",
      "Completed Within 6mo",
      "No Task Found",
    ]),
  };
};

export const _overdueTasks = () => {
  return {
    labels: ["Overdue Tasks"],
    datasets: StackedGreenYellowRedGraySetup([
      "Not Cmpleted",
      "Not Completed",
      "-",
    ]),
  };
};

export const _noOpenActivitiesCS = () => {
  return {
    labels: ["No Open Activities"],
    datasets: StackedGreenYellowRedGraySetup([
      "Not Cmpleted",
      "Not Completed",
      "-",
    ]),
  };
};

export const _completionOfSAItasks = () => {
  return {
    labels: ["Completion of SAI tasks"],
    datasets: StackedGreenYellowRedGraySetup([
      "No Date | Completed Late",
      "2 Day Overdue",
      "Completed Within 2 Days",
    ]),
  };
};

export const _overdueInvoices = () => {
  return {
    labels: ["Overdue Payments"],
    datasets: StackedGreenYellowRedGraySetup(["0", "1+", "-"]),
  };
};

export const _discountExpiring = () => {
  return {
    labels: ["Expiring Discounts"],
    datasets: StackedGreenYellowRedGraySetup([
      "Bundle Discount",
      "Expiring Discount",
      "-",
    ]),
  };
};

export const _pausedProducts = () => {
  return {
    labels: ["Paused Products"],
    datasets: StackedGreenYellowRedGraySetup([
      "Expired",
      "Expires This Month",
      "Not Expired",
    ]),
  };
};

export const _burstAccelerators = () => {
  return {
    labels: ["Burst & Accelerators"],
    datasets: StackedGreenYellowRedGraySetup([
      "Expired",
      "Expires This Month",
      "Not Expired",
    ]),
  };
};

export const _dmOnly = () => {
  return {
    labels: ["DM Only"],
    datasets: StackedGreenYellowRedGraySetup(["-", "-", "-"]),
  };
};

export const _accountByProducts = () => {
  return {
    labels: [
      "Call Tracking Only",
      "Gargle Data Widget",
      "Website Hosting Only - Gargle",
      "Membership Discount",
      "Gargle Social + Accelerator",
      "Web/SEO Burst",
      "Gargle Social + | Bundle Discount",
      "Gargle Social + (Facebook/Instagram Ads)",
      "Website Call Tracking",
      "Google Ads (Pay Per Click) - Gargle | Bundle Discount",
      "Google Ads (Pay Per Click) - Gargle",
      "Internal Marketing Cards (Display Only)",
      "Swell Suite | Bundle Discount",
      "Swell Data Widget",
      "Swell Suite",
      "Neighborhood Route Cards (Display Only)",
      "Social Media Content and Posting | Bundle Discount",
      "Social Media Content and Posting",
      "Website/SEO - Gargle",
      "Gargle Monthly Membership",
      "New Move-In Mailers (Display Only)",
      "Website / SEO | Bundle Discount",
    ],
    datasets: [
      {
        label: "0",
        data: [],
        backgroundColor: [
          multicolor[0],
          multicolor[1],
          multicolor[2],
          multicolor[3],
          orangeColor,
          multicolor[0],
          multicolor[1],
          multicolor[2],
          multicolor[3],
          orangeColor,
          multicolor[0],
          multicolor[1],
          multicolor[2],
          multicolor[3],
          orangeColor,
          multicolor[0],
          multicolor[1],
          multicolor[2],
          multicolor[3],
          orangeColor,
          multicolor[0],
          multicolor[1],
        ],
        hoverOffset: 2,
      },
    ],
  };
};

export const _credits = () => {
  return {
    labels: ["Credits"],
    datasets: StackedGreenYellowRedGraySetup([
      "over $1,000",
      "less than $1,000",
    ]),
  };
};

export const _pausedPorducts = () => {
  return {
    labels: ["Paused Porducts"],
    datasets: StackedGreenYellowRedGraySetup([
      "5+ paused products",
      "less than 5",
    ]),
  };
};

export const _draftGM = () => {
  return {
    labels: ["Draft Gargle Memberships"],
    datasets: StackedGreenYellowRedGraySetup(["draft"]),
  };
};

export const _refunds = () => {
  return {
    labels: ["Refunds"],
    datasets: StackedGreenYellowRedGraySetup([
      "over $1,000",
      "less than $1,000",
    ]),
  };
};

export const _welcomeCallTimeline = () => {
  return {
    labels: ["Welcome Call Timeline"],
    datasets: StackedGreenYellowRedGraySetup([
      "4 business days or more",
      "1-3 days",
    ]),
  };
};

export const _onboardingCallTimeline = () => {
  return {
    labels: ["Onboarding Call Timeline"],
    datasets: StackedGreenYellowRedGraySetup([
      "3 business days or more",
      "1-2 business days",
    ]),
  };
};

export const _connectionTimelineSM = () => {
  return {
    labels: ["Connection Timeline Social Media"],
    datasets: StackedGreenYellowRedGraySetup(["3 days or more", "1-2 days"]),
  };
};

export const _connectionTimelineSP = () => {
  return {
    labels: ["Connection Timeline Social Plus"],
    datasets: StackedGreenYellowRedGraySetup(["3 days or more", "1-2 days"]),
  };
};

export const _connectionTimelinePPC = () => {
  return {
    labels: ["Connection Timeline PPC"],
    datasets: StackedGreenYellowRedGraySetup(["3 days or more", "1-2 days"]),
  };
};

export const _connectionTimelineSEO = () => {
  return {
    labels: ["Connection Timeline SEO"],
    datasets: StackedGreenYellowRedGraySetup(["3 days or more", "1-2 days"]),
  };
};

export const _swellConnections = () => {
  return {
    labels: ["Swell Connections"],
    datasets: StackedGreenYellowRedGraySetup(["accounts disconnected"]),
  };
};

export const _productCancellations = () => {
  return {
    labels: ["Product Cancellations"],
    datasets: StackedGreenYellowRedGraySetup(["$3,000 or more", "under $3000"]),
  };
};

export const _clientCancellations = () => {
  return {
    labels: ["Client Cancellations"],
    datasets: StackedGreenYellowRedGraySetup([
      "5 or more locations",
      "under 5 locations",
    ]),
  };
};

export const _collections = () => {
  return {
    labels: ["Collections"],
    datasets: StackedGreenYellowRedGraySetup(["outstanding/overdue payments"]),
  };
};

export const _productCancellationsByReason = () => {
  return {
    labels: [
      "Cutting costs",
      "Too expensive",
      "Moved to competitor",
      "No ROI",
      "Went in-house",
      "Lack of product feature(s)",
      "Didn't use product",
      "Bad customer experience",
    ],
    datasets: [
      {
        label: "0",
        data: [],
        backgroundColor: [
          blueColor,
          "rgb(0, 191, 255)",
          "rgb(255, 191, 0)",
          "rgb(128, 128, 128)",
          multicolor[3],
          multicolor[1],
          blueColor,
          "rgb(0, 191, 255)",
        ],
        hoverOffset: 2,
      },
    ],
  };
};

export const _sarsScheduledThisWeek = () => {
  return {
    labels: ["Sar's Scheduled This Week"],
    datasets: StackedGreenYellowRedGraySetup(["-"]),
  };
};

export const _sarsScheduledThisMonth = () => {
  return {
    labels: ["Sar's Scheduled This Month"],
    datasets: StackedGreenYellowRedGraySetup(["-"]),
  };
};

export const _scheduleSARTasksThisWeek = () => {
  return {
    labels: ["Schedule SAR Tasks This Week"],
    datasets: StackedGreenYellowRedGraySetup(["-"]),
  };
};
