import axios from "axios";
import { auth } from "./firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";

///// NEW VERSION OF THE API /////
const apiBaseURL = () => {
  let baseURL = process.env.REACT_APP_API_BASE_URL;
  if (baseURL.endsWith("/")) {
    baseURL = baseURL.slice(0, -1);
  }
  return baseURL;
};

// Function to authenticate and get JWT token
export const authenticate = async () => {
  const userCredential = await signInWithEmailAndPassword(
    auth,
    process.env.REACT_APP_INTEGRATIONS_USR,
    process.env.REACT_APP_INTEGRATIONS_PSW
  );
  const authenticationToken = await userCredential.user.getIdToken();
  axios.defaults.headers.common["x-user-authorization"] = authenticationToken;

  const tokenResponse = await axios.post(`${apiBaseURL()}/generate-token`, {
    userId: process.env.REACT_APP_INTEGRATIONS_USR,
    apiKey: process.env.REACT_APP_APIKEY,
    organizationId: process.env.REACT_APP_INTEGRATIONS_ORGID,
  });
  axios.defaults.headers.common["Authorization"] = tokenResponse.data.token;

  const sfTokenResponse = await axios.post(`${apiBaseURL()}/sf-login`, {
    organizationId: process.env.REACT_APP_INTEGRATIONS_ORGID,
  });

  return {
    backendToken: tokenResponse.data.token,
    sfToken: sfTokenResponse.data.accessToken,
    sfInstanceUrl: sfTokenResponse.data.instanceUrl,
  };
};

export const getUserInfo = async () => {
  let response = await axios.post(`${apiBaseURL()}/userInfo`);
  const user = response.data;
  response = await axios.post(`${apiBaseURL()}/organizationInfo`);
  user.organization = response.data;

  return user;
};

export const askAI = async (prompt, contextId, contextPlatform) => {
  const response = await axios.post(`${apiBaseURL()}/ask-ai`, {
    prompt: prompt,
    contextId: contextId,
    contextPlatform: contextPlatform,
  });
  return response.data;
};

export const trainAI = async (posts) => {
  const response = await axios.post(`${apiBaseURL()}/train-ai`, {
    posts: posts,
  });
  return response.data;
};

///// NEW VERSION OF THE API /////

const authorize = async (context) => {
  return new Promise((resolve, reject) => {
    try {
      if (
        !context.AUTH_CODE ||
        !context.AUTH_EXPIRES ||
        !context.SF_AUTH_CODE ||
        new Date().getTime() > context.AUTH_EXPIRES
      ) {
        authenticate()
          .then((response) => {
            if (response) {
              context.AUTH_CODE = response.backendToken;
              context.SF_AUTH_CODE = response.sfToken;
              context.SF_AUTH_INSTANCE = response.sfInstanceUrl;
              context.AUTH_EXPIRES = new Date().getTime() + 60 * 60 * 1000;
              resolve(context.AUTH_CODE);
            }
          })
          .catch((error) => {
            context.AUTH_CODE = "";
            context.AUTH_EXPIRES = null;
            context.SF_AUTH_CODE = null;
            context.SF_AUTH_INSTANCE = null;
            reject(context.AUTH_CODE);
          });
      } else {
        resolve(context.AUTH_CODE);
      }
    } catch (err) {
      context.AUTH_CODE = "";
      context.AUTH_EXPIRES = null;
      context.SF_AUTH_CODE = null;
      context.SF_AUTH_INSTANCE = null;
      reject(context.AUTH_CODE);
    }
  });
};

export const sfQuery = async (obj, fields, filter, context) => {
  try {
    await authorize(context); // Requests an authorization code before making actual calls

    const sParams = {
      action: "sf-search",
      accessToken: context.SF_AUTH_CODE,
      instanceUrl: context.SF_AUTH_INSTANCE,
      sObject: obj,
      fields: fields,
      filter: filter,
    };

    const response = await axios.post(`${apiBaseURL()}/sf-search`, sParams);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const sfRest = async (name, svcName, action, requestKeys, context) => {
  try {
    await authorize(context); // Requests an authorization code before making actual calls

    const sParams = {
      accessToken: context.SF_AUTH_CODE,
      instanceUrl: context.SF_AUTH_INSTANCE,
      serviceName: svcName,
      body: {
        Request: action,
        ...requestKeys,
      },
    };

    const response = await axios.post(`${apiBaseURL()}/sf-rest`, sParams);
    return response.data.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const query = async (collections, context) => {
  try {
    await authorize(context); // Requests an authorization code before making actual calls
    const response = await axios.post(`${apiBaseURL()}/query`, {
      collections: collections,
    });
    return response.data;
  } catch (err) {
    return null;
  }
};
