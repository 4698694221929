import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../App";

import Sidebar from "../../UI/Sidebar";
import ErrorMessage from "../../UI/ErrorMessage";

import { query } from "../../API";

import "../../../Public.css";
import SelectAccount from "../selectAccount";

const RecordsList = () => {
  const [patients, setPatients] = useState(null);
  const [appointments, setAppointments] = useState(null);
  const [procedures, setProcedures] = useState(null);
  const [loadingPatients, setLoadingPatients] = useState(false);
  const [loadingAppointments, setLoadingAppointments] = useState(false);
  const [loadingProcedures, setLoadingProcedures] = useState(false);
  const [error, setError] = useState(false);

  let { owId, sidebar } = useParams();
  const context = useContext(UserContext);

  const loadData = async (collectionName, setData, setLoading, id) => {
    try {
      setLoading(true);
      setData(null);
      if (id) {
        const result = await query(
          [
            {
              collectionName,
              filter: {
                $or: [
                  { "location_ids.0": Number(id) },
                  { location_id: Number(id) },
                ],
              },
            },
          ],
          context
        );
        setError(false);
        setData(result[collectionName]);
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const exportToCSV = (data, filename) => {
    if (!data || data.length === 0) return;

    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));

    data.forEach((row) => {
      const values = headers.map((header) => `"${row[header] || ""}"`);
      csvRows.push(values.join(","));
    });

    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();

    URL.revokeObjectURL(url);
  };

  const renderTable = (data, title, onExport, isLoading, isFirst) => (
    <>
      <h2>{title}</h2>
      {isFirst && (
        <h4>
          Table only displays 500 records. To view full table, Export to CSV
        </h4>
      )}
      <br />
      {isLoading ? (
        <p>Loading {title.toLowerCase()}...</p>
      ) : (
        data &&
        data.length > 0 && (
          <>
            <button onClick={onExport}>Export to CSV</button>
            <br />
            <p>Full table is: {data.length}</p>
            <br />
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    {Object.keys(data[0]).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.slice(0, 500).map((row, index) => (
                    <tr key={index}>
                      {Object.values(row).map((value, i) => (
                        <td key={i}>
                          {typeof value === "object" && value !== null
                            ? JSON.stringify(value, null, 2)
                            : value !== null && value !== undefined
                            ? value.toString()
                            : ""}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )
      )}
    </>
  );

  if (context.accessToken === null) return <></>;

  return (
    <>
      {error && <ErrorMessage />}
      {!error && (
        <>
          {!sidebar && <Sidebar />}
          <div className={owId ? "contentWOS dashboard" : "content dashboard"}>
            <div style={{ height: "100%", minHeight: "100%" }}>
              <div className="chart-container">
                <SelectAccount
                  callbackFunction={(id) => {
                    loadData(
                      "pmsPatients",
                      setPatients,
                      setLoadingPatients,
                      id
                    );
                    loadData(
                      "pmsAppointments",
                      setAppointments,
                      setLoadingAppointments,
                      id
                    );
                    loadData(
                      "pmsProcedures",
                      setProcedures,
                      setLoadingProcedures,
                      id
                    );
                  }}
                />
                <br />
                {renderTable(
                  patients,
                  "Patients",
                  () => exportToCSV(patients, "patients.csv"),
                  loadingPatients,
                  true
                )}
                <br />
                {renderTable(
                  appointments,
                  "Appointments",
                  () => exportToCSV(appointments, "appointments.csv"),
                  loadingAppointments,
                  false
                )}
                <br />
                {renderTable(
                  procedures,
                  "Procedures",
                  () => exportToCSV(procedures, "procedures.csv"),
                  loadingProcedures,
                  false
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RecordsList;
