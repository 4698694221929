import React, { useEffect, useState } from "react";
import logo from "../../../logo.png"; // Replace with your logo image path

const Loader = () => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length < 3 ? prev + "." : ""));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loading-screen">
      <img src={logo} alt="Logo" className="loading-logo" />
      <div className="loading-text">Loading{dots}</div>
    </div>
  );
};

export default Loader;
