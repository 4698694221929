import "./App.css";

import React, { createContext } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Teams from "./components/VIEWS/Teams";
import SocialPlus from "./components/VIEWS/SocialPlus";
import Website from "./components/VIEWS/Website";
import PPC from "./components/VIEWS/PPC";
import AccountTable from "./components/UI/AccountTable";
import SEO from "./components/VIEWS/SEO";
import SocialMedia from "./components/VIEWS/SocialMedia";
import Audit from "./components/INTERNAL_DASHBOARDS/Audit";
import Content from "./components/VIEWS/Content";
import DirectorsDashboard from "./components/INTERNAL_DASHBOARDS/DirectorsDashboard";
import CoordinatorsDashboard from "./components/INTERNAL_DASHBOARDS/CoordinatorsDashboard";
import VPsDashboard from "./components/INTERNAL_DASHBOARDS/VPsDashboard";
import ProfitabilityDashboard from "./components/INTERNAL_DASHBOARDS/ProfitabilityDashboard";
import WebsiteDashboard from "./components/INTERNAL_DASHBOARDS/WebsiteDashboard";
import SalesStats from "./components/SalesStats";
import RecordsList from "./components/CLIENT_FACING/DataWidget/recordsList";
import RecordsOverview from "./components/CLIENT_FACING/DataWidget/recordsOverview";
import Stats from "./components/INTERNAL_DASHBOARDS/SalesStats/Stats";
import StatsLastMonth from "./components/INTERNAL_DASHBOARDS/SalesStats/StatsLastMonth";
import StatsLastQuarter from "./components/INTERNAL_DASHBOARDS/SalesStats/StatsLastQuarter";
import StatsThisQuarter from "./components/INTERNAL_DASHBOARDS/SalesStats/StatsThisQuarter";
import StatsThisLastMonth from "./components/INTERNAL_DASHBOARDS/SalesStats/StatsThisLastMonth";

export const UserContext = createContext(null);

function App() {
  /////////////////////////////////////////////// USE THIS FLAG TO PUT EVERYTHING IN MAINTENANCE
  const inMaintenance = false;

  return (
    <>
      {inMaintenance && (
        <div
          style={{
            height: "100%",
            width: "100%",
            textAlign: "center",
            marginTop: "100px",
          }}
        >
          We are doing some maintenance. We will be back soon :)
        </div>
      )}
      {!inMaintenance && (
        <React.StrictMode>
          <UserContext.Provider
            value={{
              accessToken:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiJEZW50YWxNYXJrZXRpbmciLCJuYW1lIjoiU2hhd24gUm93Ym90aGFtIiwiaWF0IjoxNTE2MjM5MDIyfQ.oX7S7xeHT2nEZEdkmQTtzzDzzc29cPDnMMtmkThXhgY",
              isLoading: false,
            }}
          >
            <Router forceRefresh>
              <Route path="/" component={SalesStats} exact />
              <Route path="/teams" component={Teams} exact />
              <Route path="/report" component={AccountTable} exact />
              <Route path="/socialplus" component={SocialPlus} exact />
              <Route path="/website" component={Website} exact />
              <Route path="/ppc" component={PPC} exact />
              <Route path="/seo" component={SEO} exact />
              <Route path="/socialmedia" component={SocialMedia} exact />
              <Route path="/audit" component={Audit} exact />
              <Route path="/content" component={Content} exact />
              <Route path="/salesstats" component={SalesStats} exact />
              <Route
                path={"/directorsDashboard/:owId?/:sidebar?/:hidediv?"}
                component={DirectorsDashboard}
              />
              <Route
                path="/vpsDashboard/:vpId?/:sidebar?/:hidediv?"
                component={VPsDashboard}
                exact
              />
              <Route
                path="/coordinatorsDashboard/:csId?/:sidebar?/:hidediv?"
                component={CoordinatorsDashboard}
                exact
              />
              <Route
                path="/profitabilityDashboard"
                component={ProfitabilityDashboard}
                exact
              />
              <Route
                path="/WebsiteDashboard"
                component={WebsiteDashboard}
                exact
              />
              <Route path="/recordsList" component={RecordsList} exact />
              <Route
                path="/recordsOverview"
                component={RecordsOverview}
                exact
              />
              <Route path="/salesThisMonth" component={Stats}></Route>
              <Route
                path="/salesThisLastMonth"
                component={StatsThisLastMonth}
              ></Route>
              <Route path="/salesLastMonth" component={StatsLastMonth}></Route>
              <Route
                path="/salesLastQuarter"
                component={StatsLastQuarter}
              ></Route>
              <Route
                path="/salesThisQuarter"
                component={StatsThisQuarter}
              ></Route>
            </Router>
          </UserContext.Provider>
        </React.StrictMode>
      )}
    </>
  );
}

export default App;
