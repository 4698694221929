import { sfRest, sfQuery } from "../../API";

export const getTeams = async (context) => {
  const result = await sfRest(
    "Teams",
    "GARGLEAnalytics",
    "Teams",
    {
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterByVP: null,
      FilterBySocialPlus: null,
    },
    context
  );
  return result;
};

export const getVP = async (context) => {
  const result = await sfRest(
    "VP",
    "GARGLEAnalytics",
    "VP",
    {
      FilterByOwner: null,
      FilterByVP: context.VPId === "null" ? null : context.VPId,
      FilterBySocialPlus: null,
    },
    context
  );
  return result;
};

export const getSocialPlus = async (context) => {
  const result = await sfRest(
    "SocialPlus",
    "GARGLEAnalytics",
    "SocialPlus",
    {
      FilterByVP: null,
      FilterByOwner: null,
      FilterBySocialAdSpecialist:
        context.SocialAdSpecialistId === "null"
          ? null
          : context.SocialAdSpecialistId,
    },
    context
  );
  return result;
};

export const getWebsite = async (context) => {
  const result = await sfRest(
    "Website",
    "GARGLEAnalytics",
    "Website",
    {
      FilterByVP: null,
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterBySocialPlus: null,
    },
    context
  );
  return result;
};

export const getPPC = async (context) => {
  const result = await sfRest(
    "PPC",
    "GARGLEAnalytics",
    "PPC",
    {
      FilterByVP: null,
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterBySocialPlus: null,
    },
    context
  );

  return result;
};

export const getSEO = async (context) => {
  const result = await sfRest(
    "SEO",
    "GARGLEAnalytics",
    "SEO",
    {
      FilterByVP: null,
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterBySocialPlus: null,
    },
    context
  );
  return result;
};

export const getSocialMedia = async (context) => {
  const result = await sfRest(
    "SocialMediaContent",
    "GARGLEAnalytics",
    "SocialMediaContent",
    {
      FilterByVP: null,
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterBySocialPlus: null,
    },
    context
  );
  return result;
};

export const getAudit = async (context) => {
  const result = await sfRest(
    "Audit",
    "GARGLEAnalytics",
    "Audit",
    {
      FilterByVP: null,
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterBySocialPlus: null,
    },
    context
  );
  return result;
};

export const getContent = async (context) => {
  const result = await sfRest(
    "Content",
    "GARGLEAnalytics",
    "Content",
    {
      FilterByVP: null,
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterBySocialPlus: null,
    },
    context
  );
  return result;
};

export const getDirectors = async (context) => {
  const result = await sfRest(
    "Directors",
    "GARGLEDirectors",
    "Directors",
    {
      FilterByVP: null,
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterBySocialPlus: null,
    },
    context
  );
  return result;
};

export const getCompletedDirAccRw = async (context) => {
  const result = await sfRest(
    "CompletedDirAccRw",
    "GARGLEDirectors",
    "CompletedDirAccRw",
    {
      FilterByVP: null,
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterBySocialPlus: null,
    },
    context
  );
  return result;
};

export const getLastVPContactTable = async (context) => {
  const result = await sfRest(
    "LastVPContactTable",
    "GARGLEDirectors",
    "LastVPContactTable",
    {
      FilterByVP: null,
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterBySocialPlus: null,
    },
    context
  );
  return result;
};

export const getVPsDashboard = async (context) => {
  const result = await sfRest(
    "VP",
    "GARGLEDirectors",
    "VP",
    {
      FilterByOwner: null,
      FilterByVP: context.VPId === "null" ? null : context.VPId,
      FilterBySocialPlus: null,
    },
    context
  );
  return result;
};

export const getWebsiteDashboard = async (context) => {
  const result = await sfRest(
    "Website",
    "GARGLEDirectors",
    "Website",
    {
      FilterByOwner: null,
      FilterByVP: context.VPId === "null" ? null : context.VPId,
      FilterBySocialPlus: null,
    },
    context
  );
  return result;
};

export const getCoordinatorsDashboard = async (context) => {
  const result = await sfRest(
    "Coordinators",
    "GARGLEDirectors",
    "Coordinators",
    {
      FilterByOwner: null,
      FilterByVP: null,
      FilterByCSC: context.CSCId === "null" ? null : context.CSCId,
      FilterBySocialPlus: null,
    },
    context
  );
  return result;
};

export const getProfitabilityDashboard = async (context) => {
  const result = await sfRest(
    "Profitability",
    "GARGLEDirectors",
    "Profitability",
    {
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterByVP: null,
      FilterBySocialPlus: null,
    },
    context
  );
  return result;
};

export const getCompletedDARCS = async (context) => {
  const result = await sfRest(
    "CompletedDAR",
    "GARGLEDirectors",
    "CompletedDAR",
    {
      FilterByOwner: null,
      FilterByVP: null,
      FilterByCSC: context.CSCId === "null" ? null : context.CSCId,
    },
    context
  );
  return result;
};

export const getCompletedVPCS = async (context) => {
  const result = await sfRest(
    "CompletedVP",
    "GARGLEDirectors",
    "CompletedVP",
    {
      FilterByOwner: null,
      FilterByVP: null,
      FilterByCSC: context.CSCId === "null" ? null : context.CSCId,
    },
    context
  );
  return result;
};

export const getCallPrepsCS = async (context) => {
  const result = await sfRest(
    "CallPrepsCS",
    "GARGLEDirectors",
    "CallPrepsCS",
    {
      FilterByOwner: null,
      FilterByVP: null,
      FilterByCSC: context.CSCId === "null" ? null : context.CSCId,
    },
    context
  );
  return result;
};

export const getCompletedSAICS = async (context) => {
  const result = await sfRest(
    "CompletedSAICS",
    "GARGLEDirectors",
    "CompletedSAICS",
    {
      FilterByOwner: null,
      FilterByVP: null,
      FilterByCSC: context.CSCId === "null" ? null : context.CSCId,
    },
    context
  );
  return result;
};

export const getConnectionSEO = async (context) => {
  const result = await sfRest(
    "ConnectionSEO",
    "GARGLEDirectors",
    "ConnectionSEO",
    {
      FilterByVP: context.VPId === "null" ? null : context.VPId,
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterByCSC: context.CSCId === "null" ? null : context.CSCId,
    },
    context
  );
  return result;
};

export const getConnectionPPC = async (context) => {
  const result = await sfRest(
    "ConnectionPPC",
    "GARGLEDirectors",
    "ConnectionPPC",
    {
      FilterByVP: context.VPId === "null" ? null : context.VPId,
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterByCSC: context.CSCId === "null" ? null : context.CSCId,
    },
    context
  );
  return result;
};

export const getConnectionSM = async (context) => {
  const result = await sfRest(
    "ConnectionSM",
    "GARGLEDirectors",
    "ConnectionSM",
    {
      FilterByVP: context.VPId === "null" ? null : context.VPId,
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterByCSC: context.CSCId === "null" ? null : context.CSCId,
    },
    context
  );
  return result;
};

export const getConnectionSP = async (context) => {
  const result = await sfRest(
    "ConnectionSP",
    "GARGLEDirectors",
    "ConnectionSP",
    {
      FilterByVP: context.VPId === "null" ? null : context.VPId,
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterByCSC: context.CSCId === "null" ? null : context.CSCId,
    },
    context
  );
  return result;
};

export const getOnboardingCT = async (context) => {
  const result = await sfRest(
    "OnboardingCT",
    "GARGLEDirectors",
    "OnboardingCT",
    {
      FilterByVP: context.VPId === "null" ? null : context.VPId,
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterByCSC: context.CSCId === "null" ? null : context.CSCId,
    },
    context
  );
  return result;
};

export const getWelcomeCT = async (context) => {
  const result = await sfRest(
    "WelcomeCallTimeline",
    "GARGLEDirectors",
    "WelcomeCallTimeline",
    {
      FilterByVP: context.VPId === "null" ? null : context.VPId,
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterByCSC: context.CSCId === "null" ? null : context.CSCId,
    },
    context
  );
  return result;
};

export const getMinStats = async (context) => {
  const memberships = await sfQuery(
    "Account",
    "Id, Name",
    `Membership_Status__c = 'Activated'
        AND (NOT Account.Name LIKE 'Test %')
        AND RecordType.Name = 'Customer'`,
    context
  );

  const overdueInvoices = await sfQuery(
    "Order",
    "Id, Name",
    `Account.Membership_Status__c='Activated'
    AND Order_Label__c LIKE 'MI-%'
    AND Payment_Date_new__c < TODAY
        AND Amount_Owed__c > 0
        AND Status = 'Activated'`,
    context
  );

  return {
    memberships: memberships.length,
    overdueInvoices: overdueInvoices.length,
  };
};
