import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../App";

import Sidebar from "../../UI/Sidebar";
import Loader from "../../UI/Loader";
import ErrorMessage from "../../UI/ErrorMessage";

import { sfQuery } from "../../API";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ProfitabilityDashboard = () => {
  const [data, setData] = useState(null);
  const [cancellations, setCancellations] = useState(0);
  const [refunds, setRefunds] = useState(0);
  const [credits, setCredits] = useState(0);
  const [error, setError] = useState(false);
  const [showOlder, setShowOlder] = useState(false);
  const context = useContext(UserContext);

  let { owId, sidebar } = useParams();

  const formatCurrency = (value) => {
    return value
      ? new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value)
      : "";
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const balanceRows = (rows1, rows2) => {
    const maxLength = Math.max(rows1.length, rows2.length);
    while (rows1.length < maxLength) {
      rows1.push({
        Opportunity: { Name: "", CloseDate: "", Total_MRR__c: 0 },
        IsPaid: false,
        IsProrated: false,
      });
    }
    while (rows2.length < maxLength) {
      rows2.push({
        Opportunity: { Name: "", CloseDate: "", Total_MRR__c: 0 },
        IsPaid: false,
        IsProrated: false,
      });
    }
  };

  const refresh = async (load) => {
    if (context.accessToken !== null && load && !context.isLoading) {
      context.isLoading = true;

      try {
        const opportunities = await sfQuery(
          "Opportunity",
          "Id, Name, AccountId, Account.Name, RecordType.Name, CloseDate, Total_MRR__c, Total_One_Offs__c, Agreement_Start_Date__c, Total_Discounts__c",
          `CloseDate >= LAST_N_MONTHS:6 AND StageName = 'Closed Won'`,
          context
        );

        const orders = await sfQuery(
          "Order",
          "Id, AccountId, Order_Label__c, Receive_Final_Payment__c, TotalAmount, EffectiveDate",
          `Order_Label__c LIKE 'MI-%' AND EffectiveDate >= LAST_N_MONTHS:6 ORDER BY EffectiveDate DESC`,
          context
        );

        const cancellationsQuery = await sfQuery(
          "OrderItem",
          "MRR__c, Cancelled_Date__c, Order.Cancelled_Date__c",
          `Order.Order_Label__c LIKE 'GM-%' 
          AND (NOT Product2.ProductCode IN ('HGGSPADSPEND', 'HGPPCADSPEND')) 
          AND (NOT Order.Account.Name LIKE '%test %') 
          AND ((Cancelled_Date__c >= LAST_N_MONTHS:6 
          AND Is_Cancelled__c = TRUE 
          AND Part_of_Cancelled_Order__c = FALSE 
          AND MRR_Exception__c = false) 
          OR (Order.Status IN ('Cancelled', 'Ala Carte Non Member') 
          AND Order.Cancelled_Date__c >= LAST_N_MONTHS:6 
          AND Part_of_Cancelled_Order__c = TRUE 
          AND Is_Cancelled__c = TRUE 
          AND MRR_Exception__c = false))`,
          context
        );

        const refundsQuery = await sfQuery(
          "Transaction__c",
          "Transaction_Amount__c, Transaction_Date__c",
          `RecordType.Name IN ('Partial Refund', 'Refund / Cancel Order') 
          AND Transaction_Date__c >= LAST_N_MONTHS:6`,
          context
        );

        const creditsQuery = await sfQuery(
          "Transaction__c",
          "Transaction_Amount__c",
          `RecordType.Name IN ('Credit') 
          AND Transaction_Date__c >= LAST_N_MONTHS:6`,
          context
        );

        setData(processOpportunities(opportunities, orders));
        setCancellations(procesCancellations(cancellationsQuery));
        setRefunds(processTransactions(refundsQuery));
        setCredits(processTransactions(creditsQuery));
        setError(false);
      } catch (err) {
        console.error(err);
        setError(true);
      } finally {
        context.isLoading = false;
      }
    }
  };

  const procesCancellations = (orderProds) => {
    const groupedData = {};

    orderProds.forEach((op) => {
      const cancelledDate = op.Order.Cancelled_Date__c || op.Cancelled_Date__c;

      const month = new Date(cancelledDate).toLocaleString("default", {
        month: "long",
        year: "numeric",
      });

      if (!groupedData[month]) {
        groupedData[month] = { Cancellations: [] };
      }

      groupedData[month].Cancellations.push(op);
    });

    return groupedData;
  };

  const processTransactions = (transactions) => {
    const groupedData = {};

    transactions.forEach((op) => {
      const month = new Date(op.Transaction_Date__c).toLocaleString("default", {
        month: "long",
        year: "numeric",
      });

      if (!groupedData[month]) {
        groupedData[month] = { Transactions: [] };
      }

      groupedData[month].Transactions.push(op);
    });

    return groupedData;
  };

  const processOpportunities = (opportunities, orders) => {
    const ordersByAccount = orders.reduce((acc, order) => {
      if (!acc[order.AccountId]) acc[order.AccountId] = [];
      acc[order.AccountId].push(order);
      return acc;
    }, {});

    const groupedData = {};

    opportunities.forEach((opp) => {
      const isBackEnd = ["Up Sell", "Product Activation"].includes(
        opp.RecordType.Name
      );

      const isFrontEnd = [
        "Lead Sale",
        "VP Referral",
        "Director Referral",
        "Additional Office",
        "Reactivation",
      ].includes(opp.RecordType.Name);

      const isPricingUpdate =
        opp.RecordType.Name === "Upsell Renewal" ||
        opp.RecordType.Name === "Renewal";

      const relatedOrders = ordersByAccount[opp.AccountId] || [];
      let matchedOrder;

      if (isFrontEnd) {
        matchedOrder = relatedOrders.find(
          (order) =>
            order.Order_Label__c.startsWith("MI-1-") ||
            (opp.RecordType.Name === "Reactivation" &&
              order.Order_Label__c.startsWith("MI-") &&
              order.Receive_Final_Payment__c >= opp.CloseDate)
        );
      } else if (isBackEnd) {
        matchedOrder = relatedOrders.find(
          (order) =>
            Math.abs(new Date(order.EffectiveDate) - new Date(opp.CloseDate)) <=
            2 * 24 * 60 * 60 * 1000
        );
      }

      const isPaid =
        (opp.Total_MRR__c === 0 && opp.Total_One_Offs__c === 0) ||
        (matchedOrder && matchedOrder.Receive_Final_Payment__c !== null);
      const isProrated =
        opp.Agreement_Start_Date__c &&
        new Date(opp.Agreement_Start_Date__c) - new Date(opp.CloseDate) >
          7 * 24 * 60 * 60 * 1000;
      const isOverdue = matchedOrder && !matchedOrder.Receive_Final_Payment__c;
      const isOneOff = opp.Total_One_Offs__c && opp.Total_One_Offs__c > 0;

      const month = new Date(opp.CloseDate).toLocaleString("default", {
        month: "long",
        year: "numeric",
      });

      if (!groupedData[month]) {
        groupedData[month] = { FrontEnd: [], BackEnd: [], PricingUpdate: [] };
      }

      if (isPricingUpdate) {
        groupedData[month].PricingUpdate.push({
          Opportunity: opp,
          IsPaid: true,
          IsProrated: isProrated,
          IsOneOff: isOneOff,
        });
      } else {
        const group = isFrontEnd ? "FrontEnd" : "BackEnd";
        groupedData[month][group].push({
          Opportunity: opp,
          MatchedOrder: matchedOrder,
          IsPaid: isPaid,
          IsProrated: isProrated,
          IsOverdue: isOverdue,
          IsOneOff: isOneOff,
        });
      }
    });

    return groupedData;
  };

  const calculateSummaryDataPoint = (s, opp) => {
    return (
      s +
      (opp.Opportunity.Total_MRR__c || opp.Opportunity.Total_One_Offs__c || 0)
    );
  };

  const renderSummary = (
    dataPoint,
    cancellationsPoint,
    refundsPoint,
    creditsPoint
  ) => {
    const totalNewRevenueSoldFE = dataPoint.FrontEnd.reduce(
      calculateSummaryDataPoint,
      0
    );
    const totalNewRevenueSoldBE = dataPoint.BackEnd.reduce(
      calculateSummaryDataPoint,
      0
    );
    const totalNewRevenueSoldPricing = dataPoint.PricingUpdate.reduce(
      calculateSummaryDataPoint,
      0
    );

    const totalNewRevenueSold =
      totalNewRevenueSoldBE +
      totalNewRevenueSoldFE +
      totalNewRevenueSoldPricing;

    const totalNewRevenueCollectedFE = dataPoint.FrontEnd.filter(
      (opp) => opp.IsPaid
    ).reduce(calculateSummaryDataPoint, 0);
    const totalNewRevenueCollectedBE = dataPoint.BackEnd.filter(
      (opp) => opp.IsPaid
    ).reduce(calculateSummaryDataPoint, 0);
    const totalNewRevenueCollectedPricing = dataPoint.PricingUpdate.filter(
      (opp) => opp.IsPaid
    ).reduce(calculateSummaryDataPoint, 0);

    const totalNewRevenueCollected =
      totalNewRevenueCollectedBE +
      totalNewRevenueCollectedFE +
      totalNewRevenueCollectedPricing;

    const totalDiscountsFE = dataPoint.FrontEnd.reduce(
      (s, opp) => s + (opp.Opportunity.Total_Discounts__c || 0),
      0
    );
    const totalDiscountsBE = dataPoint.BackEnd.reduce(
      (s, opp) => s + (opp.Opportunity.Total_Discounts__c || 0),
      0
    );
    const totalDiscountsPricing = dataPoint.BackEnd.reduce(
      (s, opp) => s + (opp.Opportunity.Total_Discounts__c || 0),
      0
    );

    const totalCancellations = cancellationsPoint.Cancellations.reduce(
      (s, opp) => s + (opp.MRR__c || 0),
      0
    );

    const totalRefunds = refundsPoint.Transactions.reduce(
      (s, opp) => s + (opp.Transaction_Amount__c || 0),
      0
    );

    const totalCredits = creditsPoint.Transactions.reduce(
      (s, opp) => s + (opp.Transaction_Amount__c || 0),
      0
    );

    return (
      <div className="card-container">
        <div className="card">
          <h3>New Revenue Sold</h3>
          <p>Total: {formatCurrency(totalNewRevenueSold)}</p>
          <hr />
          <p>FE: {formatCurrency(totalNewRevenueSoldFE)}</p>
          <p>BE: {formatCurrency(totalNewRevenueSoldBE)}</p>
          <p>Renewal: {formatCurrency(totalNewRevenueSoldPricing)}</p>
        </div>
        <div className="card">
          <h3>New Revenue Collected</h3>
          <p>Total: {formatCurrency(totalNewRevenueCollected)}</p>
          <hr />
          <p>FE: {formatCurrency(totalNewRevenueCollectedFE)}</p>
          <p>BE: {formatCurrency(totalNewRevenueCollectedBE)}</p>
          <p>Renewal: {formatCurrency(totalNewRevenueCollectedPricing)}</p>
        </div>
        <div className="card">
          <h3>Revenue Lost</h3>
          <hr />
          <p>Discounts FE: {formatCurrency(totalDiscountsFE)}</p>
          <p>Discounts BE: {formatCurrency(totalDiscountsBE)}</p>
          <p>Cancellations: {formatCurrency(totalCancellations)}</p>
          <p>Refunds: {formatCurrency(totalRefunds)}</p>
          <p>Credits: {formatCurrency(totalCredits)}</p>
        </div>
      </div>
    );
  };

  const getNotes = (row) => {
    return [
      row.IsOneOff && (
        <span
          style={{ fontSize: "0.8em", color: "#007bff", marginLeft: "5px" }}
        >
          One-Off{" "}
        </span>
      ),
      row.IsProrated && (
        <span
          style={{ fontSize: "0.8em", color: "#007bff", marginLeft: "5px" }}
        >
          Prorated (
          {new Date(
            row.Opportunity.Agreement_Start_Date__c
          ).toLocaleDateString()}
          )
        </span>
      ),
      row.IsOverdue && (
        <span
          style={{ fontSize: "0.8em", color: "#ff4d4d", marginLeft: "5px" }}
        >
          Overdue
        </span>
      ),
    ].filter(Boolean); // Filter out null or undefined elements
  };

  const renderTable = (rows, title) => {
    return (
      <div style={{ marginBottom: "20px" }}>
        <h3>{title}</h3>
        <table>
          <thead>
            <tr>
              <th style={{ padding: "4px", fontSize: "11px" }}>
                Opportunity Name
              </th>
              <th style={{ padding: "4px", fontSize: "11px" }}>Close Date</th>
              <th style={{ padding: "4px", fontSize: "11px" }}>Amount</th>
              <th style={{ padding: "4px", fontSize: "11px" }}>Notes</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, idx) => (
              <tr
                key={idx}
                style={{
                  backgroundColor: row.IsPaid
                    ? row.IsProrated
                      ? "#e6f2ff"
                      : "#e6f9e6"
                    : "#f9e6e6",
                }}
              >
                <td
                  style={{
                    padding: "4px",
                    fontSize: "11px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={row.Opportunity.Name}
                >
                  {row.Opportunity.Name ? (
                    <a
                      href={`https://gargleinc.lightning.force.com/${row.Opportunity.Id}`}
                      target="_blank"
                    >
                      {truncateText(row.Opportunity.Name, 60)}
                    </a>
                  ) : (
                    ""
                  )}
                </td>
                <td
                  style={{
                    padding: "4px",
                    fontSize: "11px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={new Date(
                    row.Opportunity.CloseDate
                  ).toLocaleDateString()}
                >
                  {row.Opportunity.CloseDate
                    ? new Date(row.Opportunity.CloseDate).toLocaleDateString()
                    : "."}
                </td>
                <td
                  style={{
                    padding: "4px",
                    fontSize: "11px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={formatCurrency(
                    row.Opportunity.Total_MRR__c &&
                      row.Opportunity.Total_MRR__c > 0
                      ? row.Opportunity.Total_MRR__c
                      : row.Opportunity.Total_One_Offs__c &&
                        row.Opportunity.Total_One_Offs__c > 0
                      ? row.Opportunity.Total_One_Offs__c
                      : 0
                  )}
                >
                  {formatCurrency(
                    row.Opportunity.Total_MRR__c &&
                      row.Opportunity.Total_MRR__c > 0
                      ? row.Opportunity.Total_MRR__c
                      : row.Opportunity.Total_One_Offs__c &&
                        row.Opportunity.Total_One_Offs__c > 0
                      ? row.Opportunity.Total_One_Offs__c
                      : ""
                  )}
                </td>
                <td
                  style={{
                    padding: "4px",
                    fontSize: "11px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={getNotes(row)}
                >
                  {getNotes(row)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    refresh(!data);
  }, [data]);

  if (context.accessToken === null) return <></>;
  if (error) return <ErrorMessage />;
  if (!data) return <Loader />;

  const currentMonth = new Date().toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  const lastMonthDate = new Date();
  lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
  const lastMonth = lastMonthDate.toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  const lastMonthData = data[lastMonth] || {
    FrontEnd: [],
    BackEnd: [],
    PricingUpdate: [],
  };
  const currentMonthData = data[currentMonth] || {
    FrontEnd: [],
    BackEnd: [],
    PricingUpdate: [],
  };

  const lastMonthCancellations = cancellations[lastMonth] || {
    Cancellations: [],
  };
  const currentMonthCancellations = cancellations[currentMonth] || {
    Cancellations: [],
  };

  const lastMonthRefunds = refunds[lastMonth] || {
    Transactions: [],
  };
  const currentMonthRefunds = refunds[currentMonth] || {
    Transactions: [],
  };

  const lastMonthCredits = refunds[lastMonth] || {
    Transactions: [],
  };
  const currentMonthCredits = refunds[currentMonth] || {
    Transactions: [],
  };

  balanceRows(lastMonthData.FrontEnd, currentMonthData.FrontEnd);
  balanceRows(lastMonthData.BackEnd, currentMonthData.BackEnd);
  balanceRows(lastMonthData.PricingUpdate, currentMonthData.PricingUpdate);

  return (
    <>
      {error && <ErrorMessage />}
      {!error && (
        <>
          {!sidebar && <Sidebar />}
          <div className={owId ? "contentWOS dashboard" : "content dashboard"}>
            <div style={{ height: "100%", minHeight: "100%" }}>
              <div className="chart-container">
                <h1>Profitability Dashboard</h1>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "48%" }}>
                    <h2>{lastMonth}</h2>
                    {renderSummary(
                      lastMonthData,
                      lastMonthCancellations,
                      lastMonthRefunds,
                      lastMonthCredits
                    )}
                    <h3>
                      {formatCurrency(
                        [
                          ...lastMonthData.FrontEnd,
                          ...lastMonthData.BackEnd,
                          ...lastMonthData.PricingUpdate,
                        ].reduce(
                          (sum, item) => sum + item.Opportunity.Total_MRR__c,
                          0
                        )
                      )}
                      <span style={{ marginLeft: "10px", color: "blue" }}>
                        (One-Offs:{" "}
                        {formatCurrency(
                          [
                            ...lastMonthData.FrontEnd,
                            ...lastMonthData.BackEnd,
                            ...lastMonthData.PricingUpdate,
                          ].reduce(
                            (sum, item) =>
                              sum + (item.Opportunity.Total_One_Offs__c || 0),
                            0
                          )
                        )}
                        )
                      </span>
                    </h3>
                    <hr />
                    {renderTable(lastMonthData.FrontEnd, "Front End")}
                    <hr />
                    {renderTable(lastMonthData.BackEnd, "Back End")}
                    <hr />
                    {renderTable(lastMonthData.PricingUpdate, "Pricing Update")}
                  </div>
                  <div style={{ width: "48%" }}>
                    <h2>{currentMonth}</h2>
                    {renderSummary(
                      currentMonthData,
                      currentMonthCancellations,
                      currentMonthRefunds,
                      currentMonthCredits
                    )}
                    <h3>
                      {formatCurrency(
                        [
                          ...currentMonthData.FrontEnd,
                          ...currentMonthData.BackEnd,
                          ...currentMonthData.PricingUpdate,
                        ].reduce(
                          (sum, item) => sum + item.Opportunity.Total_MRR__c,
                          0
                        )
                      )}
                      <span style={{ marginLeft: "10px", color: "blue" }}>
                        (One-Offs:{" "}
                        {formatCurrency(
                          [
                            ...currentMonthData.FrontEnd,
                            ...currentMonthData.BackEnd,
                            ...currentMonthData.PricingUpdate,
                          ].reduce(
                            (sum, item) =>
                              sum + (item.Opportunity.Total_One_Offs__c || 0),
                            0
                          )
                        )}
                        )
                      </span>
                    </h3>
                    <hr />
                    {renderTable(currentMonthData.FrontEnd, "Front End")}
                    <hr />
                    {renderTable(currentMonthData.BackEnd, "Back End")}
                    <hr />
                    {renderTable(
                      currentMonthData.PricingUpdate,
                      "Pricing Update"
                    )}
                  </div>
                </div>
                <button onClick={() => setShowOlder(!showOlder)}>
                  {showOlder ? "Hide Older Data" : "Show Older Data"}
                </button>
                {showOlder && (
                  <div>
                    {Object.entries(data)
                      .filter(
                        ([month]) =>
                          month !== currentMonth && month !== lastMonth
                      )
                      .map(([month, groupData]) => (
                        <div key={month}>
                          <h2>{month}</h2>
                          {renderTable(groupData.FrontEnd, "Front End")}
                          {renderTable(groupData.BackEnd, "Back End")}
                          {renderTable(
                            groupData.PricingUpdate,
                            "Pricing Update"
                          )}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <style jsx>{`
        .content {
          padding: 20px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin: 10px 0;
        }
        th,
        td {
          border: 1px solid #ddd;
          text-align: left;
          padding: 4px;
          font-size: 11px;
        }
        th {
          background-color: #f4f4f4;
          font-weight: bold;
        }
        .card-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          margin-bottom: 20px;
        }
        .card {
          width: 100%;
          margin: 10px;
          padding: 15px;
          border: 1px solid #ddd;
          border-radius: 8px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          background-color: #f9f9f9;
          text-align: left;
        }
        .card h3 {
          font-size: 14px;
          margin-bottom: 10px;
        }
        .card p {
          font-size: 12px;
          margin: 0;
          display: inline-block;
          margin-left: 15px;
        }
        .card hr {
          margin: 5px;
        }

        @media (max-width: 768px) {
          .last-month-data {
            display: none;
          }

          .notes-column {
            width: 20%;
          }

          .opportunity-name-column {
            flex: 1;
          }
        }

        @media (max-width: 768px) {
          .card-container {
            flex-direction: column;
            align-items: center;
          }

          .card {
            width: 90%;
          }

          table {
            font-size: 10px;
          }

          th,
          td {
            padding: 3px;
          }
        }

        @media (max-width: 480px) {
          .card {
            padding: 10px;
          }

          th,
          td {
            font-size: 9px;
            padding: 2px;
          }

          h1 {
            font-size: 20px;
          }

          h2 {
            font-size: 16px;
          }

          .content {
            padding: 10px;
          }
        }
      `}</style>
    </>
  );
};

export default ProfitabilityDashboard;
