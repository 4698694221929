import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../App";
import { getStats } from "../../../SF";
import "../../../../stats.css";
import QTStatComponent from "../QTStatComponent";

function StatsLastQuarter() {
  const [data, setData] = useState(null);

  const context = useContext(UserContext);

  useEffect(() => {
    if (!data) {
      getStats(context, "Last Quarter").then(function (result) {
        setData(result);
      });
    }
  });

  return (
    <QTStatComponent
      stats={data}
      title={"Last Quarter"}
      qtfegoal={35000}
      qtbegoal={23400}
      qtcancelgoal={12000}
    />
  );
}

export default StatsLastQuarter;
