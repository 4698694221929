import React from "react";
import Sidebar from "../Sidebar";

const ErrorMessage = () => {
  return (
    <>
      <Sidebar />
      <div className="content dashboard">
        <div className="error">
          Something went wrong. Can you try refreshing this tab? :)
          <br />
          <button
            className="btn btn-light"
            onClick={() => window.location.reload()}
          >
            Refresh
          </button>
        </div>
      </div>
    </>
  );
};

export default ErrorMessage;
