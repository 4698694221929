import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../App";
import { Row } from "react-bootstrap";

import Sidebar from "../../UI/Sidebar";
import { getAudit } from "../../SF";
import Loader from "../../UI/Loader";
import Filter from "../../UI/Filter";
import { getStorageValue } from "../../Utils";

const Audit = () => {
  const [data, setData] = useState(null);

  const context = useContext(UserContext);

  useEffect(() => {
    if (!data) {
      getAudit(context).then(function (result) {
        setData(result);
      });
    }
  }); // Initial page load

  try {
    if (getStorageValue("gargle_analytics_owner_id"))
      context.OwnerId = getStorageValue("gargle_analytics_owner_id");
  } catch (err) {
    context.OwnerId = null;
    window.localStorage.clear();
  }

  const columns = [
    "Account",
    "Last Audit Date",
    "Last Audit Details",
    "Next Prep",
    "Gargle Owner",
  ];

  const userOptionsOwners = [];
  if (data) {
    userOptionsOwners.push({ value: "null", label: "All Users" });

    for (const u of data.Owners) {
      userOptionsOwners.push({ value: u.Id, label: u.Name });
    }

    setTimeout(() => {
      window["reset"]();

      window["buildDataTable"](
        [
          { width: "300px", targets: 0 },
          { width: "150px", targets: 1 },
          { width: "300px", targets: 2 },
          { width: "300px", targets: 3 },
          { width: "150px", targets: 4 },
        ],
        [1, "asc"]
      );
    }, 500);
  }

  if (context.OwnerId)
    context.Owner = userOptionsOwners.find(
      (item) => item.value === context.OwnerId
    );

  return (
    <>
      <Sidebar />
      <div className="content">
        {!data && <Loader />}
        {data && (
          <>
            <Row>
              <Filter
                context={context}
                userOptionsOwners={userOptionsOwners}
                filter={(e, setShowFilter) => {
                  e.target.disabled = true;
                  e.target.innerHTML = "Loading...";
                  window.location.reload();
                }}
              />
              <div className="chart-container" style={{ minWidth: "1200px" }}>
                <div className="cs-title">
                  {!context.OwnerId ||
                  !context.Owner ||
                  context.Owner.value === "null"
                    ? "All Directors"
                    : context.Owner.label}{" "}
                  - {data.Accounts.length} accounts
                </div>
                <table
                  id="main-table"
                  style={{
                    display: "none",
                    minWidth: "100%",
                    width: "100%",
                  }}
                  border="0"
                  className="display stripe row-border order-column"
                >
                  <thead>
                    <tr>
                      {columns.map((c) => {
                        return <th key={"col-" + c}>{c}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {data.Accounts.map((a, index) => {
                      return (
                        <tr key={"row-" + a.Name + index}>
                          <td>
                            <a
                              href={
                                "https://gargleinc.lightning.force.com/" + a.Id
                              }
                              target={"_blank"}
                              rel="noreferrer"
                            >
                              {a.Name}
                            </a>
                          </td>
                          <td>
                            {a.LastAudit && a.LastAudit.ActivityDate}
                            {!a.LastAudit && "- Never"}
                          </td>
                          <td>
                            {a.LastAudit && (
                              <>
                                <a
                                  href={
                                    "https://gargleinc.lightning.force.com/" +
                                    a.LastAudit.Id
                                  }
                                >
                                  {a.LastAudit.Subject}
                                </a>
                              </>
                            )}
                          </td>
                          <td>
                            {a.NextPrep && (
                              <>
                                <a
                                  href={
                                    "https://gargleinc.lightning.force.com/" +
                                    a.NextPrep.Id
                                  }
                                >
                                  {a.NextPrep.Subject}
                                </a>
                                <br />({a.NextPrep.Task_Type__c})
                                <br />
                                {a.NextPrep.ActivityDate}
                              </>
                            )}
                          </td>
                          <td>{a.GargleOwner}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default Audit;
