import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../App";

import Sidebar from "../../UI/Sidebar";
import { getCoordinatorsDashboard } from "../../SF";
import {
  DMPieChart,
  DMStackedChart,
  greenColor,
  orangeColor,
  redColor,
  yellowColor,
} from "../../UI/DMCharts";
import Loader from "../../UI/Loader";
import ErrorMessage from "../../UI/ErrorMessage";

import OpenTasksTable from "../../UI/OpenTasksTable";

import {
  _callPrepsCS,
  _auditsCS,
  _completedDirectorAccReviewCS,
  _completedVPSemiAnnualReviews,
  _overdueTasks,
  _noOpenActivitiesCS,
  _completionOfSAItasks,
  _overdueInvoices,
  _discountExpiring,
  _groupTasks,
  _pausedProducts,
  _dmOnly,
  _accountByProducts,
  _burstAccelerators,
} from "../../Utils/CSInit";

import { getStorageValue, openURLInNewTab } from "../../Utils";
import Filter from "../../UI/Filter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const CoordinatorsDashboard = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  let { csId, sidebar } = useParams();

  const openTasks = [];
  const overdueTasks = [];
  const userOptionsCSC = [];

  const callPreps = _callPrepsCS();
  const callPrepsThisWeek = _callPrepsCS();
  const auditcs = _auditsCS();
  const completedDirectorAccReviews = _completedDirectorAccReviewCS();
  const completedVPSemiAnnualReviews = _completedVPSemiAnnualReviews();
  const overdueActivities = _overdueTasks();
  const noOpenActivities = _noOpenActivitiesCS();
  const completionOfSAItasks = _completionOfSAItasks();
  const overdueInvoices = _overdueInvoices();
  const discountExpiring = _discountExpiring();
  const pausedProducts = _pausedProducts();
  const dmOnly = _dmOnly();
  const accountByProducts = _accountByProducts();
  const burstAccelerators = _burstAccelerators();
  let totalABP = 0;

  const darkFont = true;

  const context = useContext(UserContext);

  const refresh = (load) => {
    if (context.accessToken !== null && load && !context.isLoading) {
      context.isLoading = true;
      getCoordinatorsDashboard(context).then(function (result) {
        refreshData(result);
      });
    }
  };

  const refreshData = (result) => {
    if (result === null) setError(true);
    else {
      setData(result);
      setError(false);
    }
  };

  useEffect(() => {
    refresh(!data);
  }); // Initial page load

  if (context.accessToken === null) return <></>;

  try {
    if (getStorageValue("gargle_analytics_csc_id"))
      context.CSCId = getStorageValue("gargle_analytics_csc_id");
  } catch (err) {
    context.CSCId = null;
    window.localStorage.clear();
  }

  if (data) {
    callPreps.datasets[2].data.push(data.CallPreps.Red);
    callPreps.datasets[1].data.push(data.CallPreps.Yellow);
    callPreps.datasets[0].data.push(data.CallPreps.Green);
    callPreps.labels[0] =
      data.CallPreps.Red + data.CallPreps.Yellow + data.CallPreps.Green;

    callPrepsThisWeek.datasets[0].data.push(data.CallPrepsLast7Days.Green);
    callPrepsThisWeek.labels[0] = data.CallPrepsLast7Days.Green;

    auditcs.datasets[2].data.push(data.AccwAudits.Red);
    auditcs.datasets[0].data.push(data.AccwAudits.Green);
    auditcs.labels[0] = data.AccwAudits.Red + data.AccwAudits.Green;

    noOpenActivities.datasets[2].data.push(data.NoOpenActivities.Red);
    noOpenActivities.labels[0] = data.NoOpenActivities.Red;

    overdueActivities.datasets[2].data.push(data.OverdueTasks.Red);
    overdueActivities.labels[0] = data.OverdueTasks.Red;

    burstAccelerators.datasets[2].data.push(data.BurstAccelerators.Red);
    burstAccelerators.datasets[1].data.push(data.BurstAccelerators.Yellow);
    burstAccelerators.datasets[0].data.push(data.BurstAccelerators.Green);
    burstAccelerators.labels[0] =
      data.BurstAccelerators.Red +
      data.BurstAccelerators.Yellow +
      data.BurstAccelerators.Green;

    pausedProducts.datasets[2].data.push(data.PausedPorducts.Red);
    pausedProducts.datasets[1].data.push(data.PausedPorducts.Yellow);
    pausedProducts.datasets[0].data.push(data.PausedPorducts.Green);
    pausedProducts.labels[0] =
      data.PausedPorducts.Red +
      data.PausedPorducts.Yellow +
      data.PausedPorducts.Green;

    dmOnly.datasets[0].data.push(data.DMOnlyReport.Green);
    dmOnly.labels[0] = data.DMOnlyReport.Green;

    completedDirectorAccReviews.datasets[2].data.push(
      data.CompletedDirectorAccountReviews.Red
    );
    completedDirectorAccReviews.datasets[0].data.push(
      data.CompletedDirectorAccountReviews.Green
    );
    completedDirectorAccReviews.labels[0] =
      data.CompletedDirectorAccountReviews.Red +
      data.CompletedDirectorAccountReviews.Green;

    completedVPSemiAnnualReviews.datasets[2].data.push(
      data.CompletedVPSemiAnnualReviews.Red
    );
    completedVPSemiAnnualReviews.datasets[0].data.push(
      data.CompletedVPSemiAnnualReviews.Green
    );
    completedVPSemiAnnualReviews.datasets[3].data.push(
      data.CompletedVPSemiAnnualReviews.Gray
    );
    completedVPSemiAnnualReviews.datasets[3].backgroundColor = orangeColor;
    completedVPSemiAnnualReviews.labels[0] =
      data.CompletedVPSemiAnnualReviews.Red +
      data.CompletedVPSemiAnnualReviews.Gray +
      data.CompletedVPSemiAnnualReviews.Green;

    completionOfSAItasks.datasets[2].data.push(data.CompletionOfSAItasks.Red);
    completionOfSAItasks.datasets[1].data.push(
      data.CompletionOfSAItasks.Yellow
    );
    completionOfSAItasks.datasets[0].data.push(data.CompletionOfSAItasks.Green);
    completionOfSAItasks.labels[0] =
      data.CompletionOfSAItasks.Red +
      data.CompletionOfSAItasks.Yellow +
      data.CompletionOfSAItasks.Green;

    overdueInvoices.datasets[2].data.push(data.OverdueInvoices.Red);
    overdueInvoices.datasets[0].data.push(data.OverdueInvoices.Green);
    overdueInvoices.labels[0] =
      data.OverdueInvoices.Red + data.OverdueInvoices.Green;

    discountExpiring.datasets[2].data.push(data.ExpiringDiscounts.Red);
    discountExpiring.datasets[1].data.push(data.ExpiringDiscounts.Gray);
    discountExpiring.labels[0] =
      data.ExpiringDiscounts.Red + data.ExpiringDiscounts.Gray;

    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Call Tracking Only"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Gargle Data Widget"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Website Hosting Only - Gargle"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Membership Discount"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Gargle Social + Accelerator"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Web/SEO Burst"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Gargle Social + | Bundle Discount"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Gargle Social + (Facebook/Instagram Ads)"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Website Call Tracking"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct[
        "Google Ads (Pay Per Click) - Gargle | Bundle Discount"
      ]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Google Ads (Pay Per Click) - Gargle"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Internal Marketing Cards (Display Only)"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Swell Suite | Bundle Discount"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Swell Data Widget"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Swell Suite"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Neighborhood Route Cards (Display Only)"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct[
        "Social Media Content and Posting | Bundle Discount"
      ]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Social Media Content and Posting"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Website/SEO - Gargle"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Gargle Monthly Membership"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["New Move-In Mailers (Display Only)"]
    );
    accountByProducts.datasets[0].data.push(
      data.AccountsByProduct["Website / SEO | Bundle Discount"]
    );

    for (const k in data.AccountsByProduct) {
      totalABP += data.AccountsByProduct[k];
    }

    _groupTasks(data.OpenTasks, openTasks, true);
    _groupTasks(data.OpenTasks, overdueTasks, false);

    userOptionsCSC.push({ value: "null", label: "All Users" });

    console.log(data);

    for (const u of data.Coordinators) {
      userOptionsCSC.push({ value: u.Id, label: u.Name });
    }

    if (context.CSCId)
      context.CSC = userOptionsCSC.find((item) => item.value === context.CSCId);
  }

  if (csId && csId !== "0") {
    context.CSCId = csId;
  }

  try {
    window["bodyScroll"]();
    if (sidebar) {
      window["startScrolling"]();
    }
  } catch (err) {
    // Do nothing
  }

  return (
    <>
      {error && <ErrorMessage />}
      {!error && (
        <>
          {sidebar ? <></> : <Sidebar />}
          <div className={csId ? "contentWOS dashboard" : "content dashboard"}>
            {!data && <Loader />}
            {data && (
              <>
                <Filter
                  context={context}
                  userOptionsCSC={userOptionsCSC}
                  filter={(e, setShowFilter) => {
                    e.target.disabled = true;
                    e.target.innerHTML = "Loading...";
                    window.location.reload();
                  }}
                />
                <div
                  style={{
                    height: "100%",
                    minHeight: "100%",
                    overflow: "auto",
                  }}
                >
                  <div
                    className="chart-container chart-col-100"
                    style={{
                      gridTemplateColumns: "25% 20% 20% 34%",
                    }}
                  >
                    <a
                      href="https://gargleinc.lightning.force.com/lightning/r/Report/00ORo000003fqQjMAI/view"
                      target="_blank"
                      className="btn btn-light btn-reports"
                      rel="noopener noreferrer"
                    >
                      BOB Alex
                    </a>
                    <a
                      href="https://gargleinc.lightning.force.com/lightning/r/Report/00ORo000003fpHlMAI/view"
                      target="_blank"
                      className="btn btn-light btn-reports"
                      rel="noopener noreferrer"
                    >
                      BOB Chelsea
                    </a>
                    <div className="cs-title">
                      {!context.CSCId ||
                      !context.CSC ||
                      context.CSC.value === "null"
                        ? "All Users"
                        : context.CSC.label}
                      <p className="cs-legend">
                        <span
                          className="square"
                          style={{ background: greenColor }}
                        ></span>
                        <span>Completed / On Time</span>
                        <span
                          className="square"
                          style={{ background: yellowColor }}
                        ></span>
                        <span>Needs Attention</span>
                        <span
                          className="square"
                          style={{ background: redColor }}
                        ></span>
                        <span>Urgent / Overdue</span>
                        <span
                          className="square"
                          style={{ background: orangeColor }}
                        ></span>
                        <span>Out of My Control</span>
                      </p>
                    </div>

                    <div
                      className="chart-col chart-col-33 stat-card"
                      style={{
                        cursor: "pointer",
                        color: "white",
                        textAlign: "center",
                      }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000Jckp2AC/view"
                        );
                      }}
                    >
                      <span data-tip data-for={"activeMembers"}>
                        <FontAwesomeIcon
                          style={{ fontSize: "25px" }}
                          icon={faInfoCircle}
                        />
                        <ReactTooltip id={"activeMembers"}>
                          {"Membership Orders with Status = Activated"}
                        </ReactTooltip>
                      </span>
                      <h2
                        style={{
                          marginTop: "50px",
                          color: "white",
                        }}
                      >
                        Active Memberships
                      </h2>
                      <h1
                        style={{
                          color: "white",
                        }}
                      >
                        {data.TotalGM.CountAcc}
                      </h1>
                    </div>

                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "100%",
                      }}
                    >
                      <div
                        className="chart-col chart-col-33 stat-card"
                        style={{
                          cursor: "pointer",
                          background: greenColor,
                          color: "white",
                          textAlign: "center",
                          height: "190px",
                          paddingBottom: "2px",
                        }}
                        onClick={(e) => {
                          openURLInNewTab(
                            "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000KCqA2AW/view"
                          );
                        }}
                      >
                        <span data-tip data-for={"auditstm"}>
                          <FontAwesomeIcon
                            style={{ fontSize: "25px" }}
                            icon={faInfoCircle}
                          />
                          <ReactTooltip id={"auditstm"}>
                            {"Number of audited accounts this month"}
                          </ReactTooltip>
                        </span>
                        <h2
                          style={{
                            marginTop: "5px",
                            color: "white",
                          }}
                        >
                          Audits This Month
                        </h2>
                        <h1
                          style={{
                            color: "white",
                          }}
                        >
                          {data.AuditsThisMonth.Green}
                        </h1>
                      </div>

                      <div
                        className="chart-col chart-col-33 stat-card"
                        style={{
                          cursor: "pointer",
                          background: greenColor,
                          color: "white",
                          textAlign: "center",
                          height: "190px",
                          paddingBottom: "2px",
                        }}
                        onClick={(e) => {
                          openURLInNewTab(
                            "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000KCqK2AW/view"
                          );
                        }}
                      >
                        <span data-tip data-for={"auditstw"}>
                          <FontAwesomeIcon
                            style={{ fontSize: "25px" }}
                            icon={faInfoCircle}
                          />
                          <ReactTooltip id={"auditstw"}>
                            {"Number of audited accounts this week"}
                          </ReactTooltip>
                        </span>
                        <h2
                          style={{
                            marginTop: "10px",
                            color: "white",
                          }}
                        >
                          Audits This Week
                        </h2>
                        <h1
                          style={{
                            color: "white",
                          }}
                        >
                          {data.AuditsThisWeek.Green}
                        </h1>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "100%",
                      }}
                    >
                      <div
                        className="chart-col chart-col-33 stat-card"
                        style={{
                          cursor: "pointer",
                          background:
                            data.NoOpenActivities.Red > 0
                              ? redColor
                              : greenColor,
                          color: "white",
                          textAlign: "center",
                          height: "190px",
                          paddingBottom: "2px",
                        }}
                        onClick={(e) => {
                          openURLInNewTab(
                            "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000Jcv42AC/view"
                          );
                        }}
                      >
                        <span data-tip data-for={"noplannedtasks"}>
                          <FontAwesomeIcon
                            style={{ fontSize: "25px" }}
                            icon={faInfoCircle}
                          />
                          <ReactTooltip id={"noplannedtasks"}>
                            {"Accounts with no Tasks with Status = Open"}
                          </ReactTooltip>
                        </span>
                        <h2
                          style={{
                            marginTop: "10px",
                            color: "white",
                          }}
                        >
                          Accts. No Planned Task
                        </h2>
                        <h1
                          style={{
                            color: "white",
                          }}
                        >
                          {data.NoOpenActivities.Red > 0
                            ? data.NoOpenActivities.Red
                            : data.NoOpenActivities.Green}
                        </h1>
                      </div>

                      <div
                        className="chart-col chart-col-33 stat-card"
                        style={{
                          cursor: "pointer",
                          background:
                            data.OverdueTasks.Red > 0 ? redColor : greenColor,
                          color: "white",
                          textAlign: "center",
                          height: "190px",
                          paddingBottom: "2px",
                        }}
                        onClick={(e) => {
                          openURLInNewTab(
                            "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000KCr32AG/view"
                          );
                        }}
                      >
                        <span data-tip data-for={"overduetasks"}>
                          <FontAwesomeIcon
                            style={{ fontSize: "25px" }}
                            icon={faInfoCircle}
                          />
                          <ReactTooltip id={"overduetasks"}>
                            {
                              "Tasks for Active accounts with Status = Open and Date < todat"
                            }
                          </ReactTooltip>
                        </span>
                        <h2
                          style={{
                            marginTop: "10px",
                            color: "white",
                          }}
                        >
                          Overdue Tasks
                        </h2>
                        <h1
                          style={{
                            color: "white",
                          }}
                        >
                          {data.OverdueTasks.Red > 0
                            ? data.OverdueTasks.Red
                            : data.OverdueTasks.Green}
                        </h1>
                      </div>
                    </div>

                    <div
                      className="chart-col"
                      style={{ cursor: "pointer", marginTop: "10px" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000K98F2AS/view?queryScope=userFolders"
                        );
                      }}
                    >
                      <DMPieChart
                        subtitle="Accounts By Products"
                        data={accountByProducts}
                        darkFont={darkFont}
                        toolTip={`Shows all active accounts by products.`}
                        isDoughnut={false}
                        total={totalABP}
                        height="370px"
                      />
                    </div>
                  </div>

                  <div
                    className="chart-container chart-col-100"
                    style={{
                      gridTemplateColumns: "24.5% 24.5% 24.5% 24.5%",
                    }}
                  >
                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        window.location.href =
                          "/report?action=getCompletedDARCS&report=CompletedDAR&fields=DaysBetween&fieldnames=Difference in Days&returnto=/coordinatorsDashboard";
                      }}
                    >
                      <DMStackedChart
                        subtitle="Completed Director Account Reviews"
                        data={completedDirectorAccReviews}
                        darkFont={darkFont}
                        toolTip={"Confirm DAR is done 1 business day or less"}
                      />
                    </div>

                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00O8W00000372NqUAI/view"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="Paused Products & Expiration Date"
                        data={pausedProducts}
                        darkFont={darkFont}
                        toolTip={"Paused Products & Expiration Date"}
                      />
                    </div>

                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000JdPT2A0/view"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="Overdue Invoices"
                        data={overdueInvoices}
                        darkFont={darkFont}
                        toolTip={"MI Orders with payment overdue"}
                      />
                    </div>
                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        window.location.href =
                          "/report?action=getCallPrepsCS&report=CallPrepsCS&fields=DaysBetween&fieldnames=Difference in Days&returnto=/coordinatorsDashboard";
                      }}
                    >
                      <DMStackedChart
                        subtitle="Call Preps"
                        data={callPreps}
                        darkFont={darkFont}
                        toolTip={
                          "Confirm Prep task completed 1 business day before the scheduled DAR, BR, and Semi-Annual Review Call"
                        }
                      />
                    </div>
                  </div>

                  <div
                    className="chart-container chart-col-100"
                    style={{
                      gridTemplateColumns: "24.5% 24.5% 24.5% 24.5%",
                    }}
                  >
                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000JNgG2AW/view"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="Call Preps This Week report"
                        data={callPrepsThisWeek}
                        darkFont={darkFont}
                        toolTip={"Confirm Preps Last 7 Days"}
                      />
                    </div>

                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000KDKU2A4/view"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="Accounts Audited This Month"
                        data={auditcs}
                        darkFont={darkFont}
                        toolTip={
                          "Account Audit Tasks (One per Account) completed within that calendar month"
                        }
                      />
                    </div>

                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        window.location.href =
                          "/report?action=getCompletedSAICS&report=CompletedSAICS&fields=SAISent_ActivityDate_Status&fieldnames=SAI Sent_Task Date_Status&returnto=/coordinatorsDashboard";
                      }}
                    >
                      <DMStackedChart
                        subtitle="Completion of SAI tasks"
                        data={completionOfSAItasks}
                        darkFont={darkFont}
                        toolTip={
                          "Confirm Complete SAI task completed 1, 2 or 3 business day"
                        }
                      />
                    </div>

                    <div
                      className="chart-container chart-col-100"
                      style={{
                        gridTemplateColumns: "50% 50%",
                      }}
                    >
                      <div style={{ padding: "5px" }}>
                        <a
                          href="https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000KDAt2AO/view"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <h4 style={{ fontSize: "20px" }}>DM Only</h4>
                          <h5 style={{ fontSize: "40px" }}>
                            {data.DMOnlyReport.Green}
                          </h5>
                        </a>
                      </div>
                      <div style={{ padding: "5px" }}>
                        <a
                          href="https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000KQHn2AO/view"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <h4 style={{ fontSize: "20px" }}>GM Only</h4>
                          <h5 style={{ fontSize: "40px" }}>
                            {data.GMOnlyReport.Green}
                          </h5>
                        </a>
                      </div>
                      <div style={{ padding: "5px" }}>
                        <a
                          href="https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000KQI72AO/view"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <h4 style={{ fontSize: "20px" }}>
                            Tasks Compl. This Week
                          </h4>
                          <h5 style={{ fontSize: "40px" }}>
                            {data.TasksCompletedThisWeek}
                          </h5>
                        </a>
                      </div>
                      <div style={{ padding: "5px" }}>
                        <a
                          href="https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000KQIM2A4/view"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <h4 style={{ fontSize: "20px" }}>
                            Tasks Compl. This Month
                          </h4>
                          <h5 style={{ fontSize: "40px" }}>
                            {data.TasksCompletedThisMonth}
                          </h5>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <OpenTasksTable
                  openTasks={openTasks}
                  overdueTasks={overdueTasks}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CoordinatorsDashboard;
