import React from "react";
import { Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Done from "../../../done.png";
import { _tasksColumns } from "../../Utils/CSInit";
import { openURLInNewTab } from "../../Utils";

const OpenTasksTable = ({ openTasks, overdueTasks }) => {
  const tasksColumns = _tasksColumns();
  const anyTask = openTasks.length > 0 || overdueTasks.length > 0;

  if (openTasks) {
    for (const data of openTasks) {
      for (const t of data) {
        if (!t.What) {
          t.What = { Name: "" };
        }
      }
    }
  }
  if (overdueTasks) {
    for (const data of overdueTasks) {
      for (const t of data) {
        if (!t.What) {
          t.What = { Name: "" };
        }
      }
    }
  }

  const openTasksTable = (
    <Row style={{ margin: "0 auto" }}>
      <Col>
        {overdueTasks.length > 0 && (
          <>
            <p
              style={{
                width: "100%",
                background: "#C3938F",
                color: "white",
                marginTop: "20px",
                marginBottom: "0",
                padding: "10px",
                textTransform: "uppercase",
                fontSize: "2rem",
              }}
            >
              Overdue
            </p>
            {overdueTasks.map((data, index) => {
              return (
                <div key={"open-table-container" + index}>
                  <p
                    style={{
                      width: "100%",
                      background: "#2b5597",
                      color: "white",
                      marginTop: "10px",
                      marginBottom: "0",
                      padding: "10px",
                      textTransform: "uppercase",
                    }}
                  >
                    {data[0].Owner.Name} - <b>{data.length}</b> Tasks
                  </p>
                  <DataTable
                    highlightOnHover
                    columns={tasksColumns}
                    data={data}
                    key={index}
                    fixedHeader={true}
                    onRowClicked={(row) =>
                      openURLInNewTab(
                        "https://gargleinc.lightning.force.com/" + row.Id
                      )
                    }
                    conditionalRowStyles={[
                      // You can also pass a callback to style for additional customization
                      {
                        when: (row) => row.What.Name === "",
                        style: (row) => ({ backgroundColor: "#F5FCB5" }),
                      },
                    ]}
                  />
                </div>
              );
            })}
          </>
        )}
        {openTasks.length > 0 && (
          <>
            <p
              style={{
                width: "100%",
                background: "#8FC3B5",
                color: "white",
                marginTop: "20px",
                marginBottom: "0",
                padding: "10px",
                textTransform: "uppercase",
                fontSize: "2rem",
              }}
            >
              Today
            </p>
            {openTasks.map((data, index) => {
              return (
                <div key={"open-tasks-container" + index}>
                  <p
                    style={{
                      width: "100%",
                      background: "#2b5597",
                      color: "white",
                      marginTop: "10px",
                      marginBottom: "0",
                      padding: "10px",
                      textTransform: "uppercase",
                    }}
                  >
                    {data[0].Owner.Name} - <b>{data.length}</b> Tasks
                  </p>
                  <DataTable
                    highlightOnHover
                    columns={tasksColumns}
                    data={data}
                    key={index}
                    fixedHeader={true}
                    onRowClicked={(row) =>
                      openURLInNewTab(
                        "https://gargleinc.lightning.force.com/" + row.Id
                      )
                    }
                    conditionalRowStyles={[
                      // You can also pass a callback to style for additional customization
                      {
                        when: (row) => row.What.Name === "",
                        style: (row) => ({ backgroundColor: "yellow" }),
                      },
                    ]}
                  />
                </div>
              );
            })}
          </>
        )}
      </Col>
    </Row>
  );

  const noTasksTable = (
    <Row style={{ margin: "0 auto", minHeight: "30px" }}>
      <Col>
        <p className="cs-title borderless" style={{ marginBottom: "0" }}>
          No Tasks <img className="all-done" src={Done} alt="All done!" />
        </p>
      </Col>
    </Row>
  );

  return (
    <>
      {anyTask && openTasksTable}
      {!anyTask && noTasksTable}
    </>
  );
};

export default OpenTasksTable;
