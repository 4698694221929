import React from "react";
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import { Bar as StackBar, Doughnut, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Done from "../../../done.png";
import DoneWhite from "../../../done-white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

const rootStyles = getComputedStyle(document.documentElement);

/* List must have x and y keys for each element of the chart */
export const DMChart = ({
  data,
  label,
  title,
  subtitle,
  type,
  colors = ["#8884d8", "#FF8065", "#99BEE6", "#E699BB"],
}) => {
  const comparativeAreas = [];

  if (Array.isArray(label)) {
    for (let i = 1; i < label.length; i++) {
      if (!type || type === "area") {
        comparativeAreas.push(
          <Area
            type="monotone"
            name={label[i]}
            dataKey={`line${i + 1}`}
            stroke={colors[i]}
            fillOpacity={1}
            fill={`url(#color${i + 1})`}
            key={i}
          />
        );
      } else {
        comparativeAreas.push(
          <Bar
            name={label[i]}
            dataKey={`line${i + 1}`}
            fill="#8884d8"
            key={i}
          />
        );
      }
    }
  }

  return (
    <>
      {title && subtitle && (
        <div className="chartTitle">
          <h2>{title}</h2>
          <h5>{subtitle}</h5>
        </div>
      )}

      {data && (!type || type === "area") && (
        <div className="chartContainer">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={730}
              height={250}
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <defs>
                {colors.map((item, index) => {
                  return (
                    <linearGradient
                      id={`color${index + 1}`}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                      key={index}
                    >
                      <stop offset="5%" stopColor={item} stopOpacity={0.5} />
                      <stop offset="95%" stopColor={item} stopOpacity={0} />
                    </linearGradient>
                  );
                })}
              </defs>
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis
                tickFormatter={(tick) => {
                  return tick.toLocaleString();
                }}
              />
              <Tooltip
                formatter={(value) => new Intl.NumberFormat("en").format(value)}
              />
              <Legend />
              <Area
                type="monotone"
                name={typeof label === "string" ? label : label[0]}
                dataKey="line1"
                stroke={colors[0]}
                fillOpacity={1}
                fill="url(#color1)"
              />
              {comparativeAreas.map((item) => (
                <>{item}</>
              ))}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}
      {data && type === "bar" && (
        <div className="chartContainer">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                name={typeof label === "string" ? label : label[0]}
                dataKey="line1"
                fill={colors[0]}
              />
              {comparativeAreas.map((item) => (
                <>{item}</>
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export const pluginOptions = (showLegends, dark = false) => {
  return {
    datalabels: {
      display: true,
      font: {
        size: 14,
      },
      color: function (context) {
        const index = context.dataIndex;
        let value = context.dataset.data[index];

        if (context.dataset.overrideLabelColor) {
          return (value = context.dataset.overrideLabelColor);
        }
        if (context.dataset.overrideLabel2Color && index === 1) {
          return (value = context.dataset.overrideLabel2Color);
        }

        //Inside the "dataset" we look for the "labels" we are using
        //and store them in a variable
        const labelColor = Array.isArray(context.dataset.backgroundColor)
          ? context.dataset.backgroundColor[index]
          : context.dataset.backgroundColor;

        //We make the condition: if a value of the label is "Strong blue"
        //then we color this label 'white'
        if (value === 0 || value === "0.00") {
          return (value = "transparent");
        } else if (
          dark ||
          labelColor === yellowColor ||
          value < 50 ||
          labelColor === grayBorderColor
        ) {
          return (value = "#171717");
        } else {
          //If it's any other label, we color it 'black'
          return (value = "white");
        }
      },
    },
    legend: {
      display: showLegends,
    },
  };
};

export const DMStackedChart = ({
  inprogress,
  data,
  title,
  subtitle,
  showLegends = false,
  horizontal = false,
  currency = false,
  percent = false,
  darkFont = false,
  toolTip = null,
  height = null,
  width = null,
  annotation = null,
}) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    type: "bar",
    hoverOffset: 20,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    animation: {
      duration: 0,
    },
    plugins: pluginOptions(showLegends, darkFont),
  };
  if (horizontal) options.indexAxis = "y";

  if (annotation) {
    options.plugins.annotation = annotation;
  }

  if (inprogress) {
    data.datasets[2].backgroundColor = orangeColor;
    data.datasets[2].borderColor = orangeColor;
  }

  if (currency) {
    if (horizontal) options.scales.x.ticks = { callback: dollarSign(2) };
    if (!horizontal) options.scales.y.ticks = { callback: dollarSign(2) };
    options.plugins.datalabels.formatter = dollarSign();
  } else if (percent) {
    if (horizontal) options.scales.x.ticks = { callback: percentSign(2) };
    if (!horizontal) options.scales.y.ticks = { callback: percentSign(2) };
    options.plugins.datalabels.formatter = percentSign();
  } else {
    if (horizontal) options.scales.x.ticks = { callback: formatNumber(2) };
    if (!horizontal) options.scales.y.ticks = { callback: formatNumber(2) };
    options.plugins.datalabels.formatter = formatNumber();
  }

  const titleImg = (getAllGreen(data) || getIsEmpty(data)) && (
    <img className="all-done" src={Done} alt="All done!" />
  );

  const style = {};
  if (height) {
    style.height = height;
    style.maxHeight = height;
  }
  if (width) {
    style.width = width;
    style.minWidth = width;
  }
  const tip = createToolTip(toolTip);
  const TheChart = React.memo(() => (
    <StackBar data={data} options={options} plugins={[ChartDataLabels]} />
  ));

  return (
    <>
      {(title || subtitle) && (
        <div className="chartTitle">
          {title && (
            <h2>
              {title} {tip} {titleImg}
            </h2>
          )}
          {subtitle && (
            <h5>
              {subtitle} {!title && tip} {!title && titleImg}
            </h5>
          )}
        </div>
      )}
      {data && (
        <div className="chartContainer" style={style}>
          {getAllGreen(data) && (
            <img
              className="all-done-white"
              src={DoneWhite}
              alt="All done!"
              style={{ left: "calc(50% + 2px)", bottom: "40px" }}
            />
          )}
          {getIsEmpty(data) && (
            <img className="all-done-white" src={Done} alt="All done!" />
          )}
          <TheChart />
        </div>
      )}
      {inprogress && (
        <>
          <hr />
          <div className="chartTitle ">
            <h5
              style={{
                marginRight: "7px",
                display: "inline",
              }}
            >
              Work In Progress:
            </h5>
            <a href={inprogress}>Link to ticket.</a>
          </div>
        </>
      )}
    </>
  );
};

export const DMPieChart = ({
  inprogress,
  data,
  title,
  subtitle,
  height = null,
  showLegends = false,
  currency = false,
  percent = false,
  darkFont = false,
  toolTip,
  isDoughnut = false,
  total = null,
  annotation = null,
}) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    hoverOffset: 1,
    type: !isDoughnut ? "pie" : "doughnut",
    plugins: pluginOptions(showLegends, darkFont),
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  if (annotation) {
    options.plugins.annotation = annotation;
  }

  if (inprogress) {
    data.datasets[2].backgroundColor = orangeColor;
    data.datasets[2].borderColor = orangeColor;
  }

  if (currency) {
    options.scales.x.ticks = { callback: dollarSign(2) };
    options.scales.y.ticks = { callback: dollarSign(2) };
    options.plugins.datalabels.formatter = dollarSign();
  } else if (percent) {
    options.scales.x.ticks = { callback: percentSign(2) };
    options.scales.y.ticks = { callback: percentSign(2) };
    options.plugins.datalabels.formatter = percentSign();
  } else {
    if (options.scales && options.scales.x)
      options.scales.x.ticks = { callback: formatNumber(2) };
    if (options.scales && options.scales.y)
      options.scales.y.ticks = { callback: formatNumber(2) };
    options.plugins.datalabels.formatter = formatNumber();
  }

  const titleImg = (getAllGreen(data) || getIsEmpty(data)) && (
    <img className="all-done" src={Done} alt="All done!" />
  );

  const tip = createToolTip(toolTip);

  return (
    <>
      {(title || subtitle) && (
        <div className="chartTitle">
          {title && (
            <h2>
              {title} {tip} {titleImg}
            </h2>
          )}
          {subtitle && (
            <h5>
              {subtitle} {!title && tip} {!title && titleImg}
            </h5>
          )}
        </div>
      )}
      {total !== null && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-10px",
            marginLeft: "-10px",
          }}
        >
          {total}
        </div>
      )}
      {data && (
        <div className="chartContainer">
          {getAllGreen(data) && (
            <img
              className="all-done-white"
              src={DoneWhite}
              alt="All done!"
              style={{ left: "calc(50% - 12px)", bottom: "100px" }}
            />
          )}
          {getIsEmpty(data) && (
            <img className="all-done-white" src={Done} alt="All done!" />
          )}
          {!isDoughnut && (
            <Pie data={data} options={options} plugins={[ChartDataLabels]} />
          )}
          {isDoughnut && (
            <Doughnut
              data={data}
              options={options}
              plugins={[ChartDataLabels]}
            />
          )}
          {inprogress && (
            <>
              <hr />
              <div className="chartTitle ">
                <h5
                  style={{
                    marginRight: "7px",
                    display: "inline",
                  }}
                >
                  Work In Progress:
                </h5>
                <a href={inprogress}>Link to ticket.</a>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

////////////////////////////////////////////////////////////// UTIL FUNCTIONS /////////////////////////////////////
export const greenColor = rootStyles.getPropertyValue("--green-color").trim();
export const yellowColor = rootStyles.getPropertyValue("--yellow-color").trim();
export const redColor = rootStyles.getPropertyValue("--red-color").trim();
export const orangeColor = rootStyles.getPropertyValue("--orange-color").trim();
export const blueColor = rootStyles.getPropertyValue("--blue-color").trim();

export const multicolor = Array.from({ length: 10 }, (_, i) =>
  rootStyles.getPropertyValue(`--multicolor-${i}`).trim()
);

export const multicolor2 = Array.from({ length: 10 }, (_, i) =>
  rootStyles.getPropertyValue(`--multicolor2-${i}`).trim()
);

export const greenBorderColor = rootStyles
  .getPropertyValue("--green-border-color")
  .trim();
export const yellowBorderColor = rootStyles
  .getPropertyValue("--yellow-border-color")
  .trim();
export const redBorderColor = rootStyles
  .getPropertyValue("--red-border-color")
  .trim();
export const blueBorderColor = rootStyles
  .getPropertyValue("--blue-border-color")
  .trim();
export const grayBorderColor = rootStyles
  .getPropertyValue("--gray-border-color")
  .trim();

export const borderWidth = 0.5; // This remains as is.

const createToolTip = (toolTip) => {
  const id = Date.now().toString() + Math.floor(Math.random() * 100);
  const tip = toolTip ? (
    <span data-tip data-for={id}>
      <FontAwesomeIcon icon={faInfoCircle} />
      <ReactTooltip id={id}>{toolTip}</ReactTooltip>
    </span>
  ) : (
    ""
  );
  return tip;
};

const getAllGreen = (data) => {
  let total = 0;
  let green =
    data.datasets.length > 1
      ? data.datasets[0].data[0]
      : data.datasets[0].data[2];
  for (const ds of data.datasets) {
    for (const d of ds.data) {
      total += +d;
    }
  }
  return total > 0 ? green === total : false;
};

const getIsEmpty = (data) => {
  let total = 0;
  for (const ds of data.datasets) {
    for (const d of ds.data) {
      total += +d;
    }
  }
  return total === 0;
};

export const formatNumber = (interval = 1) => {
  return function (value, index) {
    if (!value) return value;

    let val =
      typeof index === "object"
        ? new Intl.NumberFormat("en-US").format(value)
        : "";

    val =
      typeof index === "number" && index % interval === 0
        ? numberFormatter(value)
        : val;

    return val;
  };
};

export const dollarSign = (interval = 1) => {
  return function (value, index) {
    let val =
      typeof index === "object"
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumSignificantDigits: 2,
          }).format(value)
        : "";

    val =
      typeof index === "number" && index % interval === 0
        ? "$" + numberFormatter(value)
        : val;

    return val;
  };
};

export const percentSign = (interval = 1) => {
  return function (value, index) {
    return typeof index === "object" || index % interval === 0
      ? new Intl.NumberFormat("en-US", {
          maximumSignificantDigits: 2,
        }).format(value) + " %"
      : "";
  };
};

const numberFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(0) + "K";
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(0) + "M";
  } else if (num < 900) {
    return num;
  }
};

export const StackedMulticolorSetup = (labels) => {
  const arr = [];
  for (let i = 0; i < labels.length; i++) {
    arr.push({
      label: labels[i],
      data: [],
      backgroundColor: multicolor[i], // green
      borderColor: "white",
      borderWidth: borderWidth,
    });
  }
  return arr;
};

export const StackedMulticolor2Setup = (labels) => {
  const arr = [];
  for (let i = 0; i < labels.length; i++) {
    arr.push({
      label: labels[i],
      data: [],
      backgroundColor: multicolor2[i], // green
      borderColor: "white",
      borderWidth: borderWidth,
    });
  }
  return arr;
};

export const StackedGreenYellowRedSetup = (labels) => {
  return [
    {
      label: labels[2],
      data: [],
      backgroundColor: greenColor, // green
      borderColor: greenBorderColor,
      borderWidth: borderWidth,
    },
    {
      label: labels[1],
      data: [],
      backgroundColor: yellowColor, // yellow
      borderColor: yellowBorderColor,
      borderWidth: borderWidth,
    },
    {
      label: labels[0],
      data: [],
      backgroundColor: redColor, // red
      borderColor: redBorderColor,
      borderWidth: borderWidth,
    },
  ];
};

export const StackedGreenRedSetup = (labels) => {
  return [
    {
      label: labels[1],
      data: [],
      backgroundColor: greenColor, // green
      borderColor: greenBorderColor,
      borderWidth: borderWidth,
    },
    {
      label: labels[0],
      data: [],
      backgroundColor: redColor, // red
      borderColor: redBorderColor,
      borderWidth: borderWidth,
    },
  ];
};

export const StackedGreenYellowRedGraySetup = (labels) => {
  return [
    {
      label: labels[2],
      data: [],
      backgroundColor: greenColor, // green
      borderColor: greenBorderColor,
      borderWidth: borderWidth,
    },
    {
      label: labels[1],
      data: [],
      backgroundColor: yellowColor, // yellow
      borderColor: yellowBorderColor,
      borderWidth: borderWidth,
    },
    {
      label: labels[0],
      data: [],
      backgroundColor: redColor, // red
      borderColor: redBorderColor,
      borderWidth: borderWidth,
    },
    {
      label: labels[3],
      data: [],
      backgroundColor: yellowColor, // gray - replaced by yellow
      borderColor: yellowBorderColor,
      borderWidth: borderWidth,
    },
  ];
};
