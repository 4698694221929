import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../App";
import { Row } from "react-bootstrap";

import Sidebar from "../../UI/Sidebar";
import { getSocialPlus } from "../../SF";
import Loader from "../../UI/Loader";
import Filter from "../../UI/Filter";
import { getStorageValue } from "../../Utils";

const SocialPlus = () => {
  const [data, setData] = useState(null);

  const context = useContext(UserContext);

  useEffect(() => {
    if (!data) {
      getSocialPlus(context).then(function (result) {
        setData(result);
        console.log(result);
      });
    }
  }); // Initial page load

  try {
    if (getStorageValue("gargle_social_ad_specialist_id"))
      context.SocialAdSpecialistId = getStorageValue(
        "gargle_social_ad_specialist_id"
      );
  } catch (err) {
    context.SocialAdSpecialistId = null;
    window.localStorage.clear();
  }

  const columns = [
    "Account",
    "Ad Specialist",
    "Account Address",
    "Social+ Record",
    "Overdue tasks",
    "Next Task",
    "Ad Budget Max",
    "Practice Contact/Page Admin",
    "Practice Email",
    "Active Products",
    "Gargle Account Owner",
    "Gargle Coordinator",
    "VP Sales",
    "Website URL",
    "Pixel Status",
    "Social+ Accelerator",
  ];

  const userOptionsOwners = [];
  if (data) {
    userOptionsOwners.push({ value: "null", label: "All Users" });

    for (const u of data.SocialAdSpecialists) {
      userOptionsOwners.push({ value: u.Id, label: u.Name });
    }

    setTimeout(() => {
      window["reset"]();

      window["buildDataTable"](
        [
          { width: "100px", targets: 3 },
          { width: "100px", targets: 4 },
          { width: "100px", targets: 5 },
          { width: "350px", targets: 6 },
          { width: "100px", targets: 7 },
        ],
        [0, "asc"]
      );
    }, 500);
  }

  if (context.SocialAdSpecialistId)
    context.SocialAdSpecialist = userOptionsOwners.find(
      (item) => item.value === context.SocialAdSpecialistId
    );

  return (
    <>
      <Sidebar />
      {
        <div className="content">
          {!data && <Loader />}
          {data && (
            <>
              <Row>
                <Filter
                  context={context}
                  userOptionsSocialAdSpecialist={userOptionsOwners}
                  filter={(e, setShowFilter) => {
                    e.target.disabled = true;
                    e.target.innerHTML = "Loading...";
                    window.location.reload();
                  }}
                />
                <div className="chart-container" style={{ minWidth: "2200px" }}>
                  <div className="cs-title">
                    {!context.SocialAdSpecialistId ||
                    !context.SocialAdSpecialist ||
                    context.SocialAdSpecialist.value === "null"
                      ? "All Social Ad Specialists"
                      : context.SocialAdSpecialist.label}{" "}
                    - {data.Accounts.length} accounts
                  </div>
                  <table
                    id="main-table"
                    style={{
                      display: "none",
                      minWidth: "100%",
                      width: "100%",
                    }}
                    border="0"
                    className="display stripe row-border order-column"
                  >
                    <thead>
                      <tr>
                        {columns.map((c) => {
                          return <th key={"col-" + c}>{c}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {data.Accounts.map((a) => {
                        return (
                          <tr key={"row-" + a.Name}>
                            <td>
                              {" "}
                              <a
                                href={
                                  "https://gargleinc.lightning.force.com/" +
                                  a.Id
                                }
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {a.Name}
                              </a>
                            </td>
                            <td>
                              {a.AdSpecialist && (
                                <>
                                  {" "}
                                  <p>{a.AdSpecialist}</p>
                                </>
                              )}
                            </td>
                            <td>
                              {a.AccountAddress && (
                                <>
                                  {" "}
                                  <p>{a.AccountAddress}</p>
                                </>
                              )}
                            </td>
                            <td>
                              {a.RelevantRecord && (
                                <>
                                  {" "}
                                  <a
                                    href={
                                      "https://gargleinc.lightning.force.com/" +
                                      a.RelevantRecord.Id
                                    }
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    Open
                                  </a>
                                </>
                              )}
                            </td>
                            <td>
                              {a.OverdueTasks &&
                                a.OverdueTasks.map((t) => {
                                  return (
                                    <p key={t.Id}>
                                      <a
                                        href={
                                          "https://gargleinc.lightning.force.com/" +
                                          t.Id
                                        }
                                        target={"_blank"}
                                        rel="noreferrer"
                                      >
                                        {t.Subject}
                                        <br />
                                        {t.ActivityDate}
                                      </a>
                                      <br />
                                      <br />
                                    </p>
                                  );
                                })}
                            </td>
                            <td>
                              {a.NextTask &&
                                a.NextTask.map((t) => {
                                  return (
                                    <p key={t.Id}>
                                      <a
                                        href={
                                          "https://gargleinc.lightning.force.com/" +
                                          t.Id
                                        }
                                        target={"_blank"}
                                        rel="noreferrer"
                                      >
                                        {t.Subject}
                                        <br />
                                        {t.ActivityDate}
                                      </a>
                                      <br />
                                      <br />
                                    </p>
                                  );
                                })}
                            </td>
                            <td>{<>{a.AdBudgetMax}</>}</td>
                            <td>
                              {a.PracticeContactPageAdmin && (
                                <>
                                  {" "}
                                  <a
                                    href={
                                      "https://gargleinc.lightning.force.com/" +
                                      a.PracticeContactPageAdminId
                                    }
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    {a.PracticeContactPageAdmin}
                                  </a>
                                </>
                              )}
                            </td>
                            <td>{<>{a.Email}</>}</td>
                            <td>
                              {a.ActiveProducts && (
                                <>
                                  {a.ActiveProducts.map((t) => {
                                    return (
                                      t.Product2 && (
                                        <p key={t.Id}>
                                          {t.Product2.Name} - $
                                          {t.Calculated_Amount__c}
                                        </p>
                                      )
                                    );
                                  })}
                                  {a.ActiveProducts &&
                                    a.ActiveProducts.length > 0 &&
                                    a.ActiveProducts[0].Order && (
                                      <>
                                        <p
                                          style={{
                                            width: "100%",
                                            height: "1px",
                                            background: "gray",
                                            margin: "5px 0",
                                          }}
                                        ></p>
                                        <p style={{ width: "100%" }}>
                                          <a
                                            href={
                                              "https://gargleinc.lightning.force.com/" +
                                              a.ActiveProducts[0].OrderId
                                            }
                                            target={"_blank"}
                                            rel="noreferrer"
                                          >
                                            {
                                              a.ActiveProducts[0].Order
                                                .Order_Label__c
                                            }{" "}
                                            - ${" "}
                                            {
                                              a.ActiveProducts[0].Order
                                                .Total_Amount__c
                                            }
                                          </a>
                                        </p>
                                      </>
                                    )}
                                </>
                              )}
                            </td>
                            <td>
                              {a.GargleAccountOwner && (
                                <>
                                  {" "}
                                  <a
                                    href={
                                      "https://gargleinc.lightning.force.com/" +
                                      a.GargleAccountOwnerId
                                    }
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    {a.GargleAccountOwner}
                                  </a>
                                </>
                              )}
                            </td>
                            <td>
                              {
                                <>
                                  <a
                                    href={
                                      "https://gargleinc.lightning.force.com/" +
                                      a.GargleCoordinatorId
                                    }
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    {a.GargleCoordinator}
                                  </a>
                                </>
                              }
                            </td>
                            <td>
                              {
                                <>
                                  <a
                                    href={
                                      "https://gargleinc.lightning.force.com/" +
                                      a.VPSalesId
                                    }
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    {a.VPSales}
                                  </a>
                                </>
                              }
                            </td>
                            <td>{<a href={a.WebsiteURL}>{a.WebsiteURL}</a>}</td>
                            <td>{<>{a.PixelStatus}</>}</td>
                            <td>
                              {
                                <input
                                  type="checkbox"
                                  value={a.SocialAccelerator}
                                  checked={a.SocialAccelerator}
                                />
                              }
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Row>
            </>
          )}
        </div>
      }
    </>
  );
};

export default SocialPlus;
