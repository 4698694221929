import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../UI/Sidebar";
import { useParams } from "react-router-dom";
import { UserContext } from "../../App";
import Loader from "../UI/Loader";
import ErrorMessage from "../UI/ErrorMessage";
import { getMinStats } from "../UI/SFData";

const SalesStats = () => {
  let { sidebar } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const context = useContext(UserContext);

  const refresh = (load) => {
    if (context.accessToken !== null && load && !context.isLoading) {
      context.isLoading = true;
      getMinStats(context).then(function (result) {
        refreshData(result);
      });
    }
  };

  const refreshData = (result) => {
    if (result === null) setError(true);
    else {
      setData(result);
      setError(false);
    }
  };

  useEffect(() => {
    refresh(!data);
  }); // Initial page load

  if (context.accessToken === null) return <></>;

  try {
    window["bodyScroll"]();
  } catch (err) {
    // Do nothing
  }

  return (
    <>
      {error && <ErrorMessage />}
      {!error && (
        <>
          {sidebar ? <></> : <Sidebar />}
          {!data && <Loader />}
          {data && (
            <>
              <div
                className={"content dashboard"}
                style={{
                  background: "white",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    padding: "10px",
                    textAlign: "center",
                    background: "rgb(30, 115, 190)",
                    marginTop: "-3px",
                  }}
                >
                  <h6
                    id="general-stats"
                    style={{ marginBottom: "0px", color: "white" }}
                  >
                    {new Date().toJSON().slice(0, 10)}{" "}
                    <span
                      style={{ width: "20px", display: "inline-block" }}
                    ></span>{" "}
                    Active Memberships: {data.memberships}{" "}
                    <span
                      style={{ width: "20px", display: "inline-block" }}
                    ></span>{" "}
                    Overdue Invoices: {data.overdueInvoices}
                  </h6>
                </div>
                <div
                  style={{
                    width: "100%",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  <button
                    onClick={(e) => {
                      document.getElementById("main-iframe").src =
                        "/salesThisLastMonth";
                    }}
                    className="btn stats-btn"
                  >
                    This & Last Month
                  </button>
                  <button
                    onClick={(e) => {
                      document.getElementById("main-iframe").src =
                        "/salesThisMonth";
                    }}
                    className="btn stats-btn"
                  >
                    This Month
                  </button>
                  <button
                    onClick={(e) => {
                      document.getElementById("main-iframe").src =
                        "/salesLastMonth";
                    }}
                    className="btn stats-btn"
                  >
                    Last Month
                  </button>
                  <button
                    onClick={(e) => {
                      document.getElementById("main-iframe").src =
                        "/salesThisQuarter";
                    }}
                    className="btn stats-btn"
                  >
                    This Quarter
                  </button>
                  <button
                    onClick={(e) => {
                      document.getElementById("main-iframe").src =
                        "/salesLastQuarter";
                    }}
                    className="btn stats-btn"
                  >
                    Last Quarter
                  </button>
                </div>
                <iframe
                  id="main-iframe"
                  title="TV Sales Stats"
                  src={
                    new Date().getMonth() === 11
                      ? "/salesThisLastMonth"
                      : "/salesThisMonth"
                  }
                  border="0"
                  style={{ height: "100vh", width: "100%", border: 0 }}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SalesStats;
