import React from "react";

function StatComponent({ stats, title }) {
  let currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  let formatCurrency = (num) => {
    return currencyFormatter.format(num).replace(".00", "");
  };

  return (
    <>
      <div className="extra">
        <div className="card" style={{ width: "100%" }}>
          <p className="numbers">{title}</p>
        </div>
      </div>
      {stats && (
        <>
          <div className="container sales">
            <div className="card">
              <p className="numbers">
                #
                {+stats.Stats.CountNewSales +
                  +stats.Stats.CountReactivations +
                  +stats.Stats.CountReferrals}{" "}
                |
                {formatCurrency(
                  +stats.Stats.AmountNewSales +
                    +stats.Stats.AmountReactivations +
                    +stats.Stats.AmountReferrals
                )}
              </p>
              <span className="title">FrontEnd</span>
              <span className="subtitle">
                (New Sales + Reactivations + Referrals)
              </span>
            </div>
            <div className="card">
              <p className="numbers">
                #{+stats.Stats.CountUpsell + +stats.Stats.CountAdditional} |
                {formatCurrency(
                  +stats.Stats.AmountUpsell + +stats.Stats.AmountAdditional
                )}
              </p>
              <span className="title">BackEnd</span>
              <span className="subtitle">(Upsells and Additional Offices)</span>
            </div>
            <div className="card">
              <p className="numbers">
                #{stats.Stats.CountOneOffs} |{" "}
                {formatCurrency(stats.Stats.AmountOneOffs)}
              </p>
              <span className="title">One Off</span>
              <span className="subtitle">(Accelerators + Add-Ons)</span>
            </div>
            <div className="card">
              <p
                className="totals sales"
                style={{
                  marginTop: "20px",
                  color: "#488052",
                }}
              >
                {formatCurrency(
                  +stats.Stats.AmountUpsell +
                    +stats.Stats.AmountNewSales +
                    +stats.Stats.AmountAdditional +
                    +stats.Stats.AmountReactivations +
                    +stats.Stats.AmountReferrals
                )}
              </p>
              <span className="title" style={{ display: "block" }}>
                Total MRR
              </span>
              <span className="subtitle">(Backend + Frontend + Referrals)</span>
              <p
                className="totals revenue"
                style={{
                  marginTop: "20px",
                  color: "#488052",
                }}
              >
                {formatCurrency(
                  +stats.Stats.AmountUpsell +
                    +stats.Stats.AmountNewSales +
                    +stats.Stats.AmountAdditional +
                    +stats.Stats.AmountReactivations +
                    +stats.Stats.AmountReferrals +
                    stats.Stats.AmountOneOffs
                )}
              </p>
              <span className="title" style={{ display: "block" }}>
                Total Revenue
              </span>
            </div>
          </div>
          <div className="extra">
            <div className="card" style={{ width: "100%" }}>
              <p className="numbers">
                {stats.AmountNewSalesPerAE &&
                  Object.entries(stats.AmountNewSalesPerAE)
                    .map((e) => {
                      return e[0] + " = " + formatCurrency(+e[1]) + " - ";
                    })
                    .toString()
                    .replaceAll(",", "")}
              </p>
              <p className="numbers">{" | "}</p>
              <p className="numbers">
                {stats.AmountUpsellPerAE &&
                  Object.entries(stats.AmountUpsellPerAE)
                    .map((e) => {
                      return e[0] + " = " + formatCurrency(+e[1]) + " - ";
                    })
                    .toString()
                    .replaceAll(",", "")}
              </p>
            </div>
          </div>
          <div className="container cancellations">
            <div className="card">
              <p className="numbers">
                #{stats.Stats.CountCancellations} |
                {formatCurrency(stats.Stats.AmountFullCancellations)}
              </p>
              <span className="title"># Full Cancellations</span>
            </div>
            <div className="card">
              <p className="numbers">
                #{stats.Stats.AmountCancelledProducts} |
                {formatCurrency(stats.Stats.AmountPartialCancellations)}
              </p>
              <span className="title"># of Cancelled Products</span>
            </div>
            <div className="card">
              <p className="totals" style={{ color: "#805648" }}>
                {formatCurrency(stats.Stats.AmountCancellations)}
              </p>
              <span className="title" style={{ display: "block" }}>
                Total Lost
              </span>
            </div>
          </div>

          <div className="container">
            <div className="card" style={{ padding: "10px" }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  background: "#F7F7F7",
                  paddingTop: "40px",
                  paddingBottom: "40px",
                }}
              >
                <p
                  className="numbers"
                  style={{
                    marginTop: "0",
                    color: "#9E5E5E",
                  }}
                >
                  {formatCurrency(+stats.Stats.AmountRefunds)}
                </p>
                <p
                  className="title"
                  style={{
                    marginTop: "-10px",
                    color: "rgb(125, 125, 125)",
                  }}
                >
                  Refunds
                </p>
              </div>
            </div>
            <div className="card" style={{ marginRight: "20px" }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  background: "#F7F7F7",
                  paddingTop: "40px",
                  paddingBottom: "40px",
                }}
              >
                <p
                  className="numbers"
                  style={{
                    marginTop: "0",
                    color: "#9E5E5E",
                  }}
                >
                  {formatCurrency(+stats.Stats.AmountCredits)}
                </p>
                <p
                  className="title"
                  style={{
                    marginTop: "-10px",
                    color: "rgb(125, 125, 125)",
                  }}
                >
                  Credits
                </p>
              </div>
            </div>
            <div className="card">
              <p className="totals" style={{ marginTop: "2px" }}>
                {formatCurrency(
                  +stats.Stats.AmountUpsell +
                    +stats.Stats.AmountNewSales +
                    +stats.Stats.AmountAdditional +
                    +stats.Stats.AmountReactivations +
                    +stats.Stats.AmountReferrals -
                    +stats.Stats.AmountCancellations
                )}
              </p>
              <p
                className="title"
                style={{
                  color: "rgb(125, 125, 125)",
                }}
              >
                NET SALES GROWTH
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default StatComponent;
