import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../App";
import { getStats } from "../../../SF";
import "../../../../stats.css";
import StatComponent from "../StatComponent";

function StatsLastMonth() {
  const [data, setData] = useState(null);

  const context = useContext(UserContext);

  useEffect(() => {
    if (!data) {
      getStats(context, "Last Month").then(function (result) {
        setData(result);
      });
    }
  });

  return <StatComponent stats={data} title={"Last Month"} />;
}

export default StatsLastMonth;
